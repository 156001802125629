import Store from "geo_me-flux/lib/store"
import ErrorStates from "../values/errorStates"

class Errors extends Store {
  static initClass() {
    this.addActions({
      FAVOURITE_SAVED(action) {
        if (!action.error) return
        this.set("state", ErrorStates.FAVOURITES_ADD)
      },
      FAVOURITES_LOADED(action) {
        if (!action.error) return
        this.set("state", ErrorStates.FAVOURITES_LOAD)
      },
      CONTRACTS_LOADED(action) {
        if (!action.error) return
        this.set("state", ErrorStates.CONTRACTS)
      },
      PRICES_LOADED(action) {
        if (!action.error) return
        this.set("state", ErrorStates.PRICING)
      },
      ERROR_MESSAGE_DISMISSED() {
        this.set("state", null)
      }
    })
  }
}
Errors.initClass()

export default Errors
