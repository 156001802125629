import React, { Component } from "react"
import ReactDOM from "react-dom"

class Modal extends Component {
  constructor(...args) {
    super(...args)
    this.rootSelector = document.body
    this.container = document.createElement("div")
  }

  componentDidMount() {
    this.rootSelector.appendChild(this.container)
  }

  componentWillUnmount() {
    this.rootSelector.removeChild(this.container)
  }

  renderModal() {
    return (
      <div className="modal" onClick={this.props.onClose}>
        <div className="modal__content">{this.props.children}</div>
      </div>
    )
  }

  render() {
    return ReactDOM.createPortal(this.renderModal(), this.container)
  }
}

export default Modal
