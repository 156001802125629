import { Command } from "geo_me-flux"

class RequestPublicPrices extends Command {
  constructor(options) {
    super(...arguments)
    this.httpClient = options.httpClient
  }

  execute(locationId) {
    this.dispatch({
      type: this.actionTypes.PRICES_REQUESTED,
    })

    this.httpClient
      .get(`/locations/${locationId}/prices`)
      .then(({ responseJSON: prices }) => {
        this.dispatch({ type: this.actionTypes.PRICES_LOADED, payload: prices })
      })
      .catch(({ error }) => {
        this.dispatch({
          type: this.actionTypes.PRICES_LOADED,
          payload: error,
          error: true,
        })
      })
  }
}

export default RequestPublicPrices
