import connectToFlux from "geo_me-react-next/lib/connectToFlux"
import React from "react"
import InfoWindowView from "./view"
import withI18n from "geo_me-react-next/lib/withI18n"
import PropTypes from "prop-types"
import { EnvironmentContext } from "geo_me-react-next/lib/context/environmentContext"
import { withContexts } from "geo_me-react-next/lib/context/withContexts"

class InfoWindow extends React.Component {
  static propTypes = {
    layoutSetMobileMapVisibility: PropTypes.func.isRequired,
    locationsUnselect: PropTypes.func.isRequired,
    routingRestoreBookmark: PropTypes.func.isRequired,
    layoutSetMobileMapVisibility: PropTypes.func.isRequired,
    locationsSelect: PropTypes.func.isRequired,
    layoutSetMobileMapVisibility: PropTypes.func.isRequired,
    locationsClearSelectedApron: PropTypes.func.isRequired,
    locationsBookARefuel: PropTypes.func.isRequired,
    favouritesAdd: PropTypes.func.isRequired,
    isMobile: PropTypes.any,
    selectedLocation: PropTypes.any,
    selectedApronId: PropTypes.any,
    mobileMapVisibility: PropTypes.any,
    authStatus: PropTypes.any,
    favourites: PropTypes.any,
    favouritesState: PropTypes.any,
  }

  componentDidMount() {
    if (this.props.mobileMapVisibility !== "hidden") {
      this.props.layoutSetMobileMapVisibility("hidden")
    }
  }

  handleDismissal() {
    this.props.locationsUnselect()
    this.props.routingRestoreBookmark()
    if (this.props.mobileMapVisibility !== "hidden") {
      this.props.layoutSetMobileMapVisibility("hidden")
    }
  }

  handleSetMobileMapVisibility() {
    const newMapVisibilityValue = this.props.mobileMapVisibility === "hidden" ? "partial" : "hidden"
    if (newMapVisibilityValue !== "hidden") {
      this.props.locationsSelect(this.props.selectedLocation)
    }
    this.props.layoutSetMobileMapVisibility(newMapVisibilityValue)
  }

  selectedApronOrAirport() {
    const { selectedLocation, selectedApronId } = this.props

    if (!selectedApronId) return selectedLocation
    return selectedLocation.aprons.find((apron) => {
      return apron.id === selectedApronId
    })
  }

  handleReturnToApronList() {
    this.props.locationsClearSelectedApron()
  }

  handleBookARefuel() {
    const selectedApronOrAirport = this.selectedApronOrAirport()
    const shipToNumber = selectedApronOrAirport.shipto_number
    this.props.locationsBookARefuel(shipToNumber)
  }

  isLocationFavourited() {
    const selectedLocation = this.selectedApronOrAirport()
    if (!this.props.favourites || this.props.favourites.length === 0) return false
    return !!this.props.favourites.find((f) => f.location_id == selectedLocation.id)
  }

  handleAddFavourite() {
    if (this.isLocationFavourited()) return
    const selectedLocation = this.selectedApronOrAirport()
    const parentId = this.props.selectedApronId ? this.props.selectedLocation.id : null
    this.props.favouritesAdd({
      id: selectedLocation.id,
      parentId: parentId,
      shipToNumber: selectedLocation.shipto_number,
      shipToName: selectedLocation.shipto_name,
      icao: selectedLocation.icao,
    })
  }

  render() {
    return (
      <InfoWindowView
        {...this.props}
        onDismissal={(...args) => this.handleDismissal(...args)}
        onSetMobileMapVisibility={(...args) => this.handleSetMobileMapVisibility(...args)}
        onReturnToApronList={(...args) => this.handleReturnToApronList(...args)}
        onBookARefuel={(...args) => this.handleBookARefuel(...args)}
        selectedLocation={this.selectedApronOrAirport()}
        isFavourite={this.isLocationFavourited()}
        onAddFavourite={(...args) => this.handleAddFavourite(...args)}
      />
    )
  }
}

const commands = {
  layoutSetMobileMapVisibility: "layout.setMobileMapVisibility",
  locationsUnselect: "locations.unselect",
  routingRestoreBookmark: "routing.restoreBookmark",
  locationsSelect: "locations.select",
  locationsClearSelectedApron: "locations.clearSelectedApron",
  locationsBookARefuel: "locations.bookARefuel",
  favouritesAdd: "favourites.add",
}

const queries = {
  isMobile: "environment.isMobile",
  selectedLocation: "locations.selected",
  selectedApronId: "locations.selectedApronId",
  mobileMapVisibility: "layout.mobileMapVisibility",
  authStatus: "environment.authStatus",
  favourites: "favourites.favourites",
  favouritesState: "favourites.status",
}

export { InfoWindow as UnwrappedInfoWindow }
export default withContexts(EnvironmentContext)(
  withI18n(connectToFlux(queries, commands)(InfoWindow))
)
