const ActionTypes = [
  "LAYOUT_ALL_UI_SECTIONS_CLOSED",
  "LAYOUT_UI_TOGGLED",
  "LAYOUT_MOBILE_MAP_VISIBILITY_CHANGED",
  "AUTHENTICATION_STARTED",
  "AUTHENTICATION_COMPLETED",
  "PUBLIC_MODE_ENTERED",
  "FAVOURITES_LOADING",
  "FAVOURITES_LOADED",
  "APRON_UNSELECTED",
  "APRON_SELECTED",
  "REFUEL_BOOKED",
  "FUEL_TYPE_SELECTED",
  "CONTRACTS_LOADED",
  "CONTRACTS_REQUESTED",
  "CONTRACT_SELECTED",
  "PRICES_LOADED",
  "PRICES_REQUESTED",
  "FAVOURITE_ADDED",
  "FAVOURITE_SAVED",
  "ERROR_MESSAGE_DISMISSED"
]
export default ActionTypes
