import LayoutStoreBase from "geo_me-sdk/lib/app/store/layout"

class Layout extends LayoutStoreBase {
  static initClass() {
    this.addActions({
      LAYOUT_ALL_UI_SECTIONS_CLOSED() {
        this.set("openedUiSections", {})
      },
      LAYOUT_UI_TOGGLED(action) {
        this.toggleValue(`openedUiSections.${action.payload}`)
      },
      LAYOUT_MOBILE_MAP_VISIBILITY_CHANGED(action) {
        this.set("mobileMapVisibility", action.payload)
      }
    })
  }

  initialize() {
    super.initialize(...arguments)
    this.set("openedUiSections", {})
    this.set("mobileMapVisibility", "hidden")
  }

  toggleValue(key) {
    const currentValue = this.get(key)
    this.set(key, !currentValue)
  }
}
Layout.initClass()

export default Layout
