import { Command } from "geo_me-flux"

class SelectContract extends Command {
  execute(contract) {
    this.dispatch({
      type: this.actionTypes.CONTRACT_SELECTED,
      payload: contract
    })
  }
}

export default SelectContract
