import Store from "geo_me-flux/lib/store"
import FavouritesStatus from "../values/favouritesStatus"

class Favourites extends Store {
  static initClass() {
    this.addActions({
      FAVOURITES_LOADING() {
        this.set("status", FavouritesStatus.LOADING)
      },
      FAVOURITES_LOADED(action) {
        this.onFavouritesLoaded(action)
      },
      FAVOURITE_ADDED(action) {
        this.addFavourite(action.payload)
      },
      FAVOURITE_SAVED(action) {
        this.onFavouritesSaved(action)
      }
    })
  }

  initialize() {
    super.initialize(...arguments)
    this.set("favourites", [])
    this.set("status", FavouritesStatus.IDLE)
  }

  addFavourite(favourite) {
    this.set("favourites", this.get("favourites").concat([favourite]))
  }

  onFavouritesSaved({ error, payload }) {
    if (error) {
      const favourites = this.get("favourites")
      this.set(
        "favourites",
        favourites.filter(favourite => favourite.location_id !== payload.location_id)
      )
    }
  }

  onFavouritesLoaded({ error, payload }) {
    this.set("status", FavouritesStatus.LOADED)
    if (!error) this.set("favourites", payload)
  }
}
Favourites.initClass()

export default Favourites
