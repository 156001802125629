import React from "react"
import PropTypes from "prop-types"
import SearchLandingPage from "geo_me-shell-aero-components/lib/components/search/landingPage"
import SearchBox from "geo_me-react-components/lib/components/searchBox"
import OptionsIcon from "../../../assets/svg/optionsIcon"
import SettingsPane from "../../settingsPane"
import FiltersPane from "../../filtersPane"
import LoginLink from "../../loginLink"
import Pane from "geo_me-shell-aero-components/lib/ui/pane"
import PlaceType from "geo_me-sdk/lib/app/value/placeType"
import _ from "lodash"
import SearchIcon from "../../../assets/svg/searchIcon"
import CompletionItem from "./completionItem"

class SearchView extends React.Component {
  static propTypes = {
    onAppPanelDimensionsChange: PropTypes.func.isRequired,
  }

  constructor(...args) {
    super(...args)
    this.state = {
      ...this.state,
      shouldShowLandingPage: true,
    }
    this.panelRef = React.createRef()
  }

  componentDidMount() {
    this.setShouldShowLandingPage()
    this.setAppPanelDimensions()
  }

  componentDidUpdate() {
    this.setAppPanelDimensions()
    this.setShouldShowLandingPage()
  }

  setShouldShowLandingPage() {
    if (this.state.shouldShowLandingPage && (!this.props.isMobile || this.props.selectedPlace)) {
      this.setState({ shouldShowLandingPage: false })
    }
  }

  setAppPanelDimensions() {
    const newAppPanelDimensions = this.getAppPanelDimensions()
    if (!_.isEqual(newAppPanelDimensions, this.currentAppPanelDimensions)) {
      this.currentAppPanelDimensions = newAppPanelDimensions
      this.props.onAppPanelDimensionsChange(this.currentAppPanelDimensions)
    }
  }

  getAppPanelDimensions() {
    const rect = this.panelRef.current?.getBoundingClientRect()
    if (!rect) return {}

    return {
      top: rect.top,
      left: rect.left,
      width: rect.right,
      height: rect.bottom,
    }
  }

  getGroupedCompletions() {
    const completions = this.props.completions
    if (!completions.length) return completions
    const resourceCompletions = _.filter(completions, { type: PlaceType.RESOURCE })
    const gApiCompletions = _.filter(completions, { type: PlaceType.GAPI })

    if (completions[0].type === "GAPI") {
      return gApiCompletions.concat(resourceCompletions)
    }
    return resourceCompletions.concat(gApiCompletions)
  }

  renderLandingPage() {
    const { translate } = this.props
    return (
      <div ref={this.panelRef}>
        <SearchLandingPage
          labelText={translate("search.landing_page_label")}
          renderLoginLink={() => <LoginLink large light />}
          renderSearchComponent={({ searchComponentProps }) => (
            <SearchBox
              {...searchComponentProps}
              {...this.props}
              placeholder={translate("search.placeholder")}
              renderToRight={({ query }) => !query && <SearchIcon className="search__icon" />}
              renderCompletionListItem={({ item, props }) => (
                <CompletionItem {...props} item={item} />
              )}
              positionCompletionsToInputWrapper
            />
          )}
        />
      </div>
    )
  }

  render() {
    const { isMobile, isSettingsWindowActive, translate } = this.props
    if (this.state.shouldShowLandingPage) return this.renderLandingPage()
    return (
      <div className="search" ref={this.panelRef}>
        <Pane innerClassName="search__pane-inner" isFirstPane={!isMobile}>
          <div className="group-padding-x group-padding-y u-pr0 u-flex-row u-flex-center">
            <SearchBox
              {...this.props}
              placeholder={translate("search.placeholder")}
              renderToRight={({ query }) => !query && <SearchIcon className="search__icon" />}
              renderCompletionListItem={({ item, props }) => (
                <CompletionItem {...props} item={item} />
              )}
              positionCompletionsToInputWrapper
            />

            <button
              className={`search__options-button ${isSettingsWindowActive ? "is-toggled" : ""}`}
              onClick={this.props.onSettingsWindowToggle}
              tabIndex="1"
            >
              <OptionsIcon />
            </button>
          </div>
        </Pane>
        <SettingsPane onDismiss={this.props.onSettingsWindowToggle} />
        <FiltersPane />
      </div>
    )
  }
}

export default SearchView
