import React from "react"

const BackArrowIcon = () => (
  <svg width="8px" height="14px" viewBox="0 0 8 14" version="1.1">
    <title>back-arrow</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <polygon id="path-1" points="31.4 170.4 30 169 24 175 30 181 31.4 179.6 26.8 175" />
      <filter x="-13.5%" y="-8.3%" width="127.0%" height="133.3%" filterUnits="objectBoundingBox" id="filter-2">
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowOffsetOuter1" />
      </filter>
    </defs>
    <g id="Aviation-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="site-information-list-private" transform="translate(-24.000000, -169.000000)">
        <g id="back-arrow">
          <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1" />
          <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1" />
        </g>
      </g>
    </g>
  </svg>
)

export default BackArrowIcon
