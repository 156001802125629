import React from "react"
import BaseMarker from "geo_me-react-components/lib/components/marker"
import { MarkerIcon, MarkerSelectedIcon } from "../../../../assets/images"

const Marker = (props) => (
  <BaseMarker {...props} title={props.subject.name} className="test-marker">
    <img src={props.isSelected ? MarkerSelectedIcon : MarkerIcon} />
  </BaseMarker>
)

export default Marker
