import React from "react"
import ButtonRounded from "geo_me-shell-aero-components/lib/ui/buttonRounded"
import DialogContainer from "geo_me-shell-aero-components/lib/ui/dialogContainer"
import FixedFooter from "../../ui/fixedFooter"
import Item from "./item"
import DistanceUnit from "geo_me-sdk/lib/app/value/distanceUnit"
import LocationsListComponent from "geo_me-react-components/lib/components/locationsList"
import PropTypes from "prop-types"
import withI18n from "geo_me-react-next/lib/withI18n"

class LocationsListView extends React.Component {
  static propTypes = {
    onCloseAllUiSections: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onSetMobileMapVisibility: PropTypes.func.isRequired,
    mobileMapVisibility: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    dialogsShouldBeDisabled: PropTypes.bool.isRequired,
    distanceUnit: PropTypes.oneOf([DistanceUnit.KM, DistanceUnit.MILES]).isRequired,
    isShowingFiltersSection: PropTypes.bool,
  }

  renderMapAndListToggle() {
    const {
      translate,
      isMobile,
      isShowingFiltersSection,
      mobileMapVisibility,
      onSetMobileMapVisibility,
    } = this.props
    if (!isMobile || isShowingFiltersSection) return null
    const buttonTranslationType = mobileMapVisibility === "hidden" ? "map" : "list"
    return (
      <FixedFooter filled={mobileMapVisibility === "hidden"}>
        <div className="group-padding-x-large group-padding-y">
          <ButtonRounded full onClick={onSetMobileMapVisibility} className="test-map-list-toggle">
            {translate(`locations_list.map_toggle.${buttonTranslationType}`)}
          </ButtonRounded>
        </div>
      </FixedFooter>
    )
  }
  render() {
    return (
      <DialogContainer
        title={this.props.translate("locations_list.title")}
        disabled={this.props.dialogsShouldBeDisabled}
        onDisabledClick={this.props.onCloseAllUiSections}
      >
        <LocationsListComponent
          {...this.props}
          renderLocation={({ item, props }) => (
            <Item {...props} {...this.props} item={item} key={item.uniqueId} />
          )}
        />
        {this.renderMapAndListToggle()}
      </DialogContainer>
    )
  }
}

export default withI18n(LocationsListView)
