import React from "react"
import PropTypes from "prop-types"
import DistanceUnit from "geo_me-sdk/lib/app/value/distanceUnit"
import PlaceIcon from "../../../assets/svg/placeIcon"
import withI18n from "geo_me-react-next/lib/withI18n"
import filterReactProps from "filter-react-props"

class LocationListItem extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
  }

  renderFuelingHours() {
    const { fuelservice } = this.props.item
    if (!fuelservice) return null
    return (
      <div className="locations-list-item__info-item locations-list-item__fuel-hours">
        {this.props.translate("locations_list.fueling_hours", { hours: fuelservice })}
      </div>
    )
  }

  renderControlTowerHours() {
    const { control_tower_hours } = this.props.item
    if (!control_tower_hours) return null
    return (
      <div className="locations-list-item__info-item locations-list-item__control-tower-hours">
        {this.props.translate("locations_list.control_tower_hours", { hours: control_tower_hours })}
      </div>
    )
  }

  renderAddressText() {
    const { city, country } = this.props.item
    return `${city}, ${country.name}`
  }

  getButtonProps() {
    return {
      onClick: this.props.onClick,
      onMouseEnter: this.props.onMouseEnter,
      onMouseLeave: this.props.onMouseLeave,
      className: this.props.className,
    }
  }

  render() {
    const {
      className,
      item: { airport_name },
    } = this.props
    return (
      <button
        {...filterReactProps(this.props)}
        className={`${className} locations-list-item u-flex-column`}
      >
        <div className="locations-list-item">
          <div className="locations-list-item__info">
            <div className="locations-list-item__info-item locations-list-item__info-item--name">
              {airport_name}
            </div>
            <div className="locations-list-item__info-item">{this.renderAddressText()}</div>
            {this.renderFuelingHours()}
            {this.renderControlTowerHours()}
          </div>
        </div>
      </button>
    )
  }
}

export default withI18n(LocationListItem)
