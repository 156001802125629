import React from "react"
import PropTypes from "prop-types"
import Pane from "geo_me-shell-aero-components/lib/ui/pane"
import ButtonToggle from "geo_me-shell-aero-components/lib/ui/buttonToggle"
import ButtonPrimary from "geo_me-shell-aero-components/lib/ui/buttonPrimary"
import DistanceUnit from "geo_me-sdk/lib/app/value/distanceUnit"
import Favourites from "./favourites"
import LoginLink from "../../loginLink"
import AuthStates from "../../../values/authStates"

class SettingsPaneView extends React.Component {
  static propTypes = {
    isSettingsWindowActive: PropTypes.bool.isRequired,
    onDistanceUnitSelect: PropTypes.func.isRequired,
    onDownloadCSVClicked: PropTypes.func.isRequired,
    distanceUnit: PropTypes.string.isRequired,
    authStatus: PropTypes.string.isRequired,
    favourites: PropTypes.array.isRequired,
    favouritesStatus: PropTypes.string.isRequired,
    onFavouriteSelect: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isSettingsWindowActive: false,
  }

  isDistanceUnitToggled(distance_unit) {
    return this.props.distanceUnit === distance_unit
  }

  renderDistanceUnitButtons() {
    return (
      <div className="u-flex-row u-flex-justify">
        <ButtonToggle
          onClick={() => this.props.onDistanceUnitSelect(DistanceUnit.MILES)}
          toggled={this.isDistanceUnitToggled(DistanceUnit.MILES)}
          value={DistanceUnit.MILES}
          className="settings__distance-button settings__distance-button--mi"
        >
          {this.props.translate(`settings.distance_units.${DistanceUnit.MILES}`)}
        </ButtonToggle>
        <ButtonToggle
          onClick={() => this.props.onDistanceUnitSelect(DistanceUnit.KM)}
          toggled={this.isDistanceUnitToggled(DistanceUnit.KM)}
          value={DistanceUnit.KM}
          className="settings__distance-button settings__distance-button--km"
        >
          {this.props.translate(`settings.distance_units.${DistanceUnit.KM}`)}
        </ButtonToggle>
      </div>
    )
  }

  renderDistanceUnitTitle() {
    return (
      <h4 className="title-primary">{this.props.translate("settings.distance_units.title")}</h4>
    )
  }

  renderFavouritesGroup() {
    if (this.props.authStatus !== AuthStates.PRIVATE) return null
    return (
      <div className="group-padding-x-small group-padding-y-small test-favourites">
        <h4 className="title-primary">{this.props.translate("settings.favourites.title")}</h4>
        <Favourites
          favourites={this.props.favourites}
          onSelect={this.props.onFavouriteSelect}
          favouritesStatus={this.props.favouritesStatus}
          translate={this.props.translate}
        />
      </div>
    )
  }

  renderDownloadCSVButton() {
    if (this.props.authStatus === AuthStates.PRIVATE) return null
    return (
      <div className="group-padding-x-small group-padding-y-small test-favourites">
        <h4 className="title-primary">{this.props.translate("settings.download_csv.title")}</h4>
        <ButtonPrimary
          small
          full
          className="test-download-button"
          onClick={this.props.onDownloadCSVClicked}
        >
          {this.props.translate("settings.download_csv.cta")}
        </ButtonPrimary>
      </div>
    )
  }

  renderSettings() {
    return (
      <Pane className="u-w100% u-z-above-level-1 u-pos-a test-settings-pane" dark isLastPane>
        <div className="settings">
          <div className="group-padding-x-small group-padding-y-small u-pb0.5 u-ta-r test-login-link">
            <LoginLink />
          </div>
          <div className="group-padding-x-small group-padding-y-small u-pt0 test-distance-units">
            {this.renderDistanceUnitTitle()}
            {this.renderDistanceUnitButtons()}
          </div>
          {this.renderFavouritesGroup()}
          {this.renderDownloadCSVButton()}
        </div>
      </Pane>
    )
  }

  render() {
    if (!this.props.isSettingsWindowActive) return null
    return this.renderSettings()
  }
}

export default SettingsPaneView
