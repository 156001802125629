import { Command } from "geo_me-flux"

class SetMobileMapVisibility extends Command {
  execute(visibilityType) {
    this.dispatch({
      type: this.actionTypes.LAYOUT_MOBILE_MAP_VISIBILITY_CHANGED,
      payload: visibilityType
    })
  }
}

export default SetMobileMapVisibility
