import React from "react"

const PlaceIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      d="M12 11.484c1.359 0 2.484-1.125 2.484-2.484s-1.125-2.484-2.484-2.484-2.484 1.125-2.484 2.484 1.125 2.484 2.484 2.484zM12 2.016c3.891 0 6.984 3.094 6.984 6.984 0 5.25-6.984 12.984-6.984 12.984s-6.984-7.734-6.984-12.984c0-3.891 3.094-6.984 6.984-6.984z"
    />
  </svg>
)

export default PlaceIcon
