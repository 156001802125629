import React, { Component } from "react"
import LocationsListContainer from "geo_me-react-components/lib/containers/locationsList"
import connectToFlux from "geo_me-react-next/lib/connectToFlux"
import LocationsListView from "./view"
import { EnvironmentContext } from "geo_me-react-next/lib/context/environmentContext"
import { withContexts } from "geo_me-react-next/lib/context/withContexts"

const getNewMapVisibilityValue = (mobileMapVisibility) => {
  if (mobileMapVisibility === "hidden") return "full"
  return "hidden"
}

const LocationsList = ({
  locations,
  layoutSetMobileMapVisibility,
  dialogsShouldBeDisabled,
  mobileMapVisibility,
  isMobile,
}) => (
  <LocationsListContainer>
    {(viewProps) => (
      <LocationsListView
        {...viewProps}
        locations={locations}
        isMobile={isMobile}
        mobileMapVisibility={mobileMapVisibility}
        dialogsShouldBeDisabled={dialogsShouldBeDisabled}
        onSetMobileMapVisibility={() =>
          layoutSetMobileMapVisibility(getNewMapVisibilityValue(mobileMapVisibility))
        }
      />
    )}
  </LocationsListContainer>
)

const commands = {
  layoutSetMobileMapVisibility: "layout.setMobileMapVisibility",
}

const queries = {
  highlightedLocation: "locations.highlighted",
  isMobile: "environment.isMobile",
  locations: "locations.views.radial",
  mobileMapVisibility: "layout.mobileMapVisibility",
  mobileMapIsToggled: "layout.mobileMapIsToggled",
  distanceUnit: "i18n.intlData.formats.number.distance.unit",
}
EnvironmentContext

export default withContexts(EnvironmentContext)(connectToFlux(queries, commands)(LocationsList))
