import React from "react"

const SearchIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" className={className}>
    <path
      fill="currentColor"
      d="m19.278154,17.021954l-1.212506,0l-0.429747,-0.414398c1.504113,-1.749694 2.409652,-4.021207 2.409652,-6.492254c0,-5.509981 -4.466302,-9.976283 -9.976273,-9.976283c-5.509981,0 -9.976283,4.466302 -9.976283,9.976283c0,5.509971 4.466302,9.976273 9.976283,9.976273c2.471048,0 4.74256,-0.90554 6.492254,-2.409652l0.414398,0.429747l0,1.212506l7.674052,7.658703l2.286872,-2.286872l-7.658703,-7.674052zm-9.208873,0c-3.821682,0 -6.906652,-3.08497 -6.906652,-6.906652c0,-3.821682 3.08497,-6.906652 6.906652,-6.906652c3.821682,0 6.906652,3.08497 6.906652,6.906652c0,3.821682 -3.08497,6.906652 -6.906652,6.906652z"
    />
  </svg>
)

export default SearchIcon
