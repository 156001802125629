import React from "react"
import PropTypes from "prop-types"

const DIRECTIONS = {
  up: 0,
  down: 180,
  right: 90,
  left: 270,
}

const ChevronIcon = ({ direction = "up" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"
      transform={`rotate(${DIRECTIONS[direction]} 12 12)`}
    />
  </svg>
)

ChevronIcon.propTypes = {
  direction: PropTypes.oneOf(Object.keys(DIRECTIONS)),
}

export default ChevronIcon
