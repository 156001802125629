import React from "react"
import PropTypes from "prop-types"
import ErrorMessageDialogView from "./view"
import connectToFlux from "geo_me-react-next/lib/connectToFlux"

class ErrorMessageDialog extends React.Component {
  static propTypes = {
    errorsDismissErrorMessage: PropTypes.func.isRequired,
    errorsState: PropTypes.string,
  }

  handleErrorDialogClose() {
    this.props.errorsDismissErrorMessage()
  }

  render() {
    return (
      <ErrorMessageDialogView
        {...this.props}
        onDismiss={(...args) => this.handleErrorDialogClose(...args)}
      />
    )
  }
}

const commands = { errorsDismissErrorMessage: "errors.dismissErrorMessage" }
const queries = { errorsState: "errors.state" }

export default connectToFlux(queries, commands)(ErrorMessageDialog)
