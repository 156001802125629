import BaseStore from "geo_me-sdk/lib/app/store/environment"
import AuthStates from "../values/authStates"

class EnvironmentStore extends BaseStore {
  static initClass() {
    this.addActions({
      PUBLIC_MODE_ENTERED(action) {
        this.set("authStatus", AuthStates.PUBLIC)
      },
      AUTHENTICATION_STARTED(action) {
        this.set("authStatus", AuthStates.PENDING)
      },
      AUTHENTICATION_COMPLETED(action) {
        if (action.error) {
          this.set("authStatus", AuthStates.FAILED)
        } else {
          this.set("authStatus", AuthStates.PRIVATE)
        }
      }
    })
  }

  initialize() {
    super.initialize(...arguments)
    this.set("authStatus", AuthStates.IDLE)
  }
}

EnvironmentStore.initClass()

export default EnvironmentStore
