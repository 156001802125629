import React, { Component } from "react"
import ApronListView from "./view"
import connectToFlux from "geo_me-react-next/lib/connectToFlux"
import PropTypes from "prop-types"
import withI18n from "geo_me-react-next/lib/withI18n"

class ApronList extends Component {
  static propTypes = {
    layoutSetMobileMapVisibility: PropTypes.func.isRequired,
    locationsUnselect: PropTypes.func.isRequired,
    routingRestoreBookmark: PropTypes.func.isRequired,
    layoutSetMobileMapVisibility: PropTypes.func.isRequired,
    locationsSelectApron: PropTypes.func.isRequired,
    locationsSelect: PropTypes.func.isRequired,
    layoutSetMobileMapVisibility: PropTypes.func.isRequired,
    isMobile: PropTypes.any,
    selectedLocation: PropTypes.any,
    selectedApronId: PropTypes.any,
    mobileMapVisibility: PropTypes.any,
  }

  componentDidMount() {
    if (this.props.mobileMapVisibility !== "hidden") {
      this.props.layoutSetMobileMapVisibility("hidden")
    }
  }

  handleDismissal() {
    this.props.locationsUnselect()
    this.props.routingRestoreBookmark()
    if (this.props.mobileMapVisibility !== "hidden") {
      this.props.layoutSetMobileMapVisibility("hidden")
    }
  }

  handleSelectApron(apronId) {
    this.props.locationsSelectApron(apronId)
  }

  handleSetMobileMapVisibility() {
    const newMapVisibilityValue = this.props.mobileMapVisibility === "hidden" ? "partial" : "hidden"
    if (newMapVisibilityValue !== "hidden") {
      this.props.locationsSelect(this.props.selectedLocation)
    }
    this.props.layoutSetMobileMapVisibility(newMapVisibilityValue)
  }

  render() {
    return (
      <ApronListView
        {...this.props}
        onDismissal={(...args) => this.handleDismissal(...args)}
        onSelectApron={(...args) => this.handleSelectApron(...args)}
        onSetMobileMapVisibility={(...args) => this.handleSetMobileMapVisibility(...args)}
      />
    )
  }
}

const commands = {
  layoutSetMobileMapVisibility: "layout.setMobileMapVisibility",
  locationsUnselect: "locations.unselect",
  routingRestoreBookmark: "routing.restoreBookmark",
  locationsSelectApron: "locations.selectApron",
  locationsSelect: "locations.select",
}
const queries = {
  isMobile: "environment.isMobile",
  selectedLocation: "locations.selected",
  selectedApronId: "locations.selectedApronId",
  mobileMapVisibility: "layout.mobileMapVisibility",
}

export { ApronList as UnwrappedApronList }
export default withI18n(connectToFlux(queries, commands)(ApronList))
