import React from "react"
import PropTypes from "prop-types"
import PointRenderer from "geo_me-react-components/lib/components/map/renderer/point"
import ClusterRenderer from "geo_me-react-components/lib/components/map/renderer/cluster"
import SearchMarkerRenderer from "geo_me-react-components/lib/components/map/renderer/searchRenderer"
import Marker from "./marker"
import Cluster from "geo_me-react-components/lib/components/cluster"

class Locations extends React.Component {
  static propTypes = {
    locations: PropTypes.array.isRequired,
    highlighted: PropTypes.object,
    selected: PropTypes.object,
    onOverlayRequest: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div>
        <PointRenderer
          selected={this.props.selected}
          highlighted={this.props.highlighted}
          subjects={this.props.locations}
          childComponent={Marker}
          overlay={this.props.onOverlayRequest("locations-points")}
          offset={{ left: 21, bottom: 30 }}
        />
        <ClusterRenderer
          subjects={this.props.locations}
          childComponent={Cluster}
          overlay={this.props.onOverlayRequest("locations-clusters")}
        />
        <SearchMarkerRenderer
          selectedPlace={this.props.selectedPlace}
          onOverlayRequest={this.props.onOverlayRequest}
        />
      </div>
    )
  }
}

export default Locations
