import React from "react"
import PropTypes from "prop-types"
import LocationsList from "../locationsList"
import InfoWindow from "../infoWindow"
import ApronList from "../apronList"
import connectToFlux from "geo_me-react-next/lib/connectToFlux"

class GlobalDialogGroup extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.getActiveDialog(this.props) !== this.getActiveDialog(prevProps)) {
      this.props.resetScrollPosition()
    }
  }

  getOpenUiSectionCount() {
    const { openedUiSections } = this.props
    if (!openedUiSections) return 0
    return Object.values(openedUiSections).filter(Boolean).length
  }

  getActiveDialog({ selectedLocation, selectedApronId, locations }) {
    if (selectedLocation) {
      if (selectedLocation.aprons && selectedLocation.aprons.length > 0 && !selectedApronId) {
        return "apronList"
      } else {
        return "infoWindow"
      }
    }
    if (locations?.length) return "locationsList"
  }

  handleCloseAllUiSections() {
    this.props.layoutCloseAllUiSections()
  }

  render() {
    const dialogProps = {
      dialogsShouldBeDisabled: this.getOpenUiSectionCount() > 0,
      onCloseAllUiSections: (...args) => this.handleCloseAllUiSections(...args),
    }

    switch (this.getActiveDialog(this.props)) {
      case "apronList":
        return <ApronList {...dialogProps} />
      case "infoWindow":
        return <InfoWindow {...dialogProps} />
      case "locationsList":
        return <LocationsList {...dialogProps} />
      default:
        return null
    }
  }
}

GlobalDialogGroup.propTypes = {
  resetScrollPosition: PropTypes.func.isRequired,
  layoutCloseAllUiSections: PropTypes.func.isRequired,
  selectedLocation: PropTypes.any,
  selectedApronId: PropTypes.any,
  locations: PropTypes.any,
  openedUiSections: PropTypes.any,
}

const commands = { layoutCloseAllUiSections: "layout.closeAllUiSections" }
const queries = {
  selectedLocation: "locations.selected",
  selectedApronId: "locations.selectedApronId",
  locations: "locations.views.radial",
  openedUiSections: "layout.openedUiSections",
}
export { GlobalDialogGroup as UnwrappedGlobalDialogGroup }
export default connectToFlux(queries, commands)(GlobalDialogGroup)
