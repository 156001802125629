import React from "react"
import PropTypes from "prop-types"
import Modal from "../ui/modal"
import DialogBox from "../ui/dialogBox"
import withI18n from "geo_me-react-next/lib/withI18n"

class ErrorMessageDialog extends React.Component {
  static propTypes = {
    errorsState: PropTypes.oneOf(["pricing", "contracts", "favourites.load", "favourites.add"]),
  }

  render() {
    if (!this.props.errorsState) return null
    return (
      <Modal>
        <DialogBox
          title={this.props.translate(`errors.${this.props.errorsState}.title`)}
          onClose={(...args) => this.props.onDismiss(...args)}
        >
          {this.props.translate(`errors.${this.props.errorsState}.message`)}
        </DialogBox>
      </Modal>
    )
  }
}

export default withI18n(ErrorMessageDialog)
