import React from "react"
import ReactDOM from "react-dom"
import Config from "./config"
import Inject from "geo_me-inject"

import LoadFavouritesCommand from "./commands/view/favourites/load"
import AddFavouriteCommand from "./commands/view/favourites/add"
import SetMobileMapVisibilityCommand from "./commands/view/layout/setMobileMapVisibility"
import CloseAllUiSectionsCommand from "./commands/view/layout/closeAllUiSections"
import ToggleUiSectionCommand from "./commands/view/layout/toggleUiSection"
import SelectApronCommand from "./commands/view/locations/selectApron"
import BookARefuelCommand from "./commands/view/locations/bookARefuel"
import ClearSelectedApronCommand from "./commands/view/locations/clearSelectedApron"
import StartAuthenticationCommand from "./commands/view/authentication/start"
import SetAuthenticationPublicCommand from "./commands/view/authentication/setPublic"
import SelectFuelTypeCommand from "./commands/view/pricing/selectFuelType"
import RequestContractsCommand from "./commands/view/pricing/requestContracts"
import SelectContractCommand from "./commands/view/pricing/selectContract"
import RequestPrivatePricesCommand from "./commands/view/pricing/requestPrivatePrices"
import RequestPublicPricesCommand from "./commands/view/pricing/requestPublicPrices"
import DismissErrorMessageCommand from "./commands/view/errors/dismissErrorMessage"
import AppComponent from "./components/app"
import LayoutStore from "./stores/layout"
import EnvironmentStore from "./stores/environment"
import LocationsStore from "./stores/resources/locations"
import FavouritesStore from "./stores/favourites"
import PricingStore from "./stores/pricing"
import ErrorsStore from "./stores/errors"
import ActionTypes from "./values/actionTypes"
import iframe from "../iframe-test.html"

import FluxBasicsFeature from "geo_me-sdk/lib/app/feature/flux/basics"
import FluxConfigFeature from "geo_me-sdk/lib/app/feature/flux/config"
import FluxFiltersFeature from "geo_me-sdk/lib/app/feature/flux/filters"
import FluxMapFeature from "geo_me-sdk/lib/app/feature/flux/map"
import FluxRoutingFeature from "geo_me-sdk/lib/app/feature/flux/routing"
import FluxSearchFeature from "geo_me-sdk/lib/app/feature/flux/search"
import GoogleSearchFeature from "geo_me-sdk/lib/app/feature/google/search"
import ResourceCompletionFeature from "geo_me-sdk/lib/app/feature/resource/completion"
import ResourceFindFeature from "geo_me-sdk/lib/app/feature/resource/find"
import SearchRoutingFeature from "geo_me-sdk/lib/app/feature/search/routing"

const { inject, feature, resolve, register, initializer, start } = Inject()
import { initLog } from "geo_me-fe-log"

// Fix issue with test iframe

if (process.env.DEPLOY_ENV === "privatetest") {
  document.domain = "geoapp.me"
} else if (process.env.DEPLOY_ENV === "privateuat" || process.env.DEPLOY_ENV === "privateprod") {
  document.domain = "shell.com"
}

initLog({
  logglyKey: "89f85593-200b-45dd-a38f-0a12d24e0a62",
  appName: "shellaviationlocator",
  deployEnv: process.env.DEPLOY_ENV,
  appVersion: document.querySelector("html").dataset.geomeVersion,
})

feature({
  FluxBasicsFeature,
  FluxConfigFeature,
  FluxFiltersFeature,
  FluxMapFeature,
  FluxRoutingFeature,
  FluxSearchFeature,
  GoogleSearchFeature,
  ResourceCompletionFeature,
  ResourceFindFeature,
  SearchRoutingFeature,
})

register("registry::routes", {
  "/"() {
    return {
      match: ["/"],
      component: () => null,
    }
  },
})

register("registry::resources", {
  locations() {
    return {
      views: {
        bounded: {
          type: "bounded",
        },
        radial: {
          type: "radial",
        },
      },
    }
  },
})

register("registry::flux::commands", {
  "authentication.start": (app) => ({
    class: StartAuthenticationCommand,
    deps: {
      httpClient: app.resolve("httpClient"),
      endpoint: "/auth",
      params: {
        iframe_url: location.href.replace(location.hash, ""),
        referer: window.document.referrer,
      },
    },
  }),
  "authentication.setPublic": () => ({
    class: SetAuthenticationPublicCommand,
  }),
  "layout.closeAllUiSections": () => ({
    class: CloseAllUiSectionsCommand,
  }),
  "layout.setMobileMapVisibility": () => ({
    class: SetMobileMapVisibilityCommand,
  }),
  "layout.toggleUiSection": () => ({
    class: ToggleUiSectionCommand,
  }),
  "locations.clearSelectedApron": () => ({
    class: ClearSelectedApronCommand,
  }),
  "locations.selectApron": () => ({
    class: SelectApronCommand,
  }),
  "locations.bookARefuel": () => ({
    class: BookARefuelCommand,
    deps: {
      parentPage: window.parent,
    },
  }),
  "favourites.load": (app) => ({
    class: LoadFavouritesCommand,
    deps: {
      httpClient: app.resolve("httpClient"),
      baseUrl: app.resolve("config").get("baseUrl"),
    },
  }),
  "favourites.add": (app) => ({
    class: AddFavouriteCommand,
    deps: {
      httpClient: app.resolve("httpClient"),
      commandHandler: app.resolve("flux::commandHandler"),
    },
  }),
  "pricing.selectFuelType": () => ({
    class: SelectFuelTypeCommand,
  }),
  "pricing.requestContracts": (app) => ({
    class: RequestContractsCommand,
    deps: {
      httpClient: app.resolve("httpClient"),
    },
  }),
  "pricing.selectContract": () => ({
    class: SelectContractCommand,
  }),
  "pricing.requestPrivatePrices": (app) => ({
    class: RequestPrivatePricesCommand,
    deps: {
      httpClient: app.resolve("httpClient"),
      commandHandler: app.resolve("flux::commandHandler"),
    },
  }),
  "pricing.requestPublicPrices": (app) => ({
    class: RequestPublicPricesCommand,
    deps: {
      httpClient: app.resolve("httpClient"),
    },
  }),
  "errors.dismissErrorMessage": () => ({
    class: DismissErrorMessageCommand,
  }),
})

register("registry::flux::stores", {
  locations: () => ({ class: LocationsStore }),
  layout: () => ({ class: LayoutStore }),
  pricing: () => ({ class: PricingStore }),
  environment: () => ({ class: EnvironmentStore }),
  favourites: () => ({ class: FavouritesStore }),
  errors: () => ({ class: ErrorsStore }),
})

register("registry::flux::actionTypes", ActionTypes)

initializer("Start authentication", (app) => {
  if (app.resolve("config").get("session_id")) {
    app
      .resolve("flux::commandHandler")
      .run("authentication.start")
      .then(() => app.resolve("flux::commandHandler").run("favourites.load"))
  } else {
    app.resolve("flux::commandHandler").run("authentication.setPublic")
  }
})

initializer("App", () => render(AppComponent))

const getAppProps = () => ({
  get: () => null,
  i18n: resolve("i18n"),
  commandHandler: resolve("flux::commandHandler"),
  queryHandler: resolve("flux::queryHandler"),
  routingHistory: resolve("routing::history"),
  routingRegistry: resolve("routing::registry"),
  mapFactory: resolve("map::factory"),
  routerProps: {
    routes: resolve("routing::registry").getComponentRoutes(),
    history: resolve("routing::history"),
  },
})

const render = (Component) => {
  ReactDOM.render(<Component {...getAppProps()} />, document.getElementById("app"))
}

document.addEventListener("DOMContentLoaded", () => start(Config))
