const filters = {
  metadata: {},
  groups: {
    fuels: {
      mode: "withAll",
      filters: {
        aerojet: {},
        fuel_avgas: {},
        fuel_jet: {}
      }
    },
    cards: {
      mode: "withAll",
      filters: {
        sav_card: {},
        amex_card: {},
        master_card: {},
        visa_card: {},
        cashacceptance: {},
        savcarnet_card: {}
      }
    },
    loyalty: {
      mode: "withAll",
      filters: {
        aerous_card: {},
        aerobrazil_card: {},
        airmilescanada_card: {}
      }
    }
  }
}

export default filters
