const areValidPrices = (prices) => {
  return (
    prices.PriceHeaders &&
    prices.PriceHeaders.length > 0 &&
    prices.PriceHeaders.every(
      (p) => typeof p.MainPriceEntry.PRICE !== "undefined" && p.MainPriceEntry.PRICE.length > 0
    )
  )
}

export default areValidPrices
