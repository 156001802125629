import React from "react"
import PropTypes from "prop-types"

const LoadingIndicator = ({title}) =>(
      <div className="loading-indicator">
        <div className="loading-indicator__title">{title}</div>
        <div className="loading-indicator__loader">
          <div className="loading-indicator__loader-bar" />
        </div>
      </div>
    )


LoadingIndicator.propTypes = {
  title: PropTypes.string
}

export default LoadingIndicator
