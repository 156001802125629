import React from "react"

const FavouriteIcon = ({ filled }) => (
  <svg width="23px" height="21px" viewBox="0 0 23 21" version="1.1">
    <title>favourite-icon-normal</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g id="Style-guide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Elements/Components"
        fill={filled ? "#FFFFFF" : "none"}
        fillRule="nonzero"
        stroke="white"
        strokeWidth="2"
        transform="translate(1, 0)"
      >
        <g id="favourite-icon-normal">
          <path
            d="M10.3313765,19.3406665 L8.8813765,18.0206665 C3.7313765,13.3506665 0.331376498,10.2706665 0.331376498,6.49066645 C0.331376498,3.41066645 2.7513765,0.990666453 5.8313765,0.990666453 C7.5713765,0.990666453 9.2413765,1.80066645 10.3313765,3.08066645 C11.4213765,1.80066645 13.0913765,0.990666453 14.8313765,0.990666453 C17.9113765,0.990666453 20.3313765,3.41066645 20.3313765,6.49066645 C20.3313765,10.2706665 16.9313765,13.3506665 11.7813765,18.0306665 L10.3313765,19.3406665 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default FavouriteIcon
