import { Command } from "geo_me-flux"

class ToggleUiSection extends Command {
  execute(uiSectionId) {
    this.dispatch({
      type: this.actionTypes.LAYOUT_UI_TOGGLED,
      payload: uiSectionId
    })
  }
}

export default ToggleUiSection
