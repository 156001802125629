import React from "react"
import Modal from "../../../ui/modal"
import DialogBox from "../../../ui/dialogBox"

class DisclaimerLink extends React.Component {
  constructor(...args) {
    super(...args)
    this.state = {
      isModalOpen: false,
    }
  }

  handleModalClose() {
    this.setState({ isModalOpen: false })
  }

  handleModalOpen() {
    this.setState({ isModalOpen: true })
  }

  renderModal() {
    return (
      <Modal>
        <DialogBox
          title={this.props.translate("info_window.disclaimer")}
          onClose={(...args) => this.handleModalClose(...args)}
        >
          {this.props.children}
        </DialogBox>
      </Modal>
    )
  }

  render() {
    return (
      <div>
        <span className={this.props.className} onClick={(...args) => this.handleModalOpen(...args)}>
          {this.props.translate("info_window.show_disclaimer")}
        </span>
        {this.state.isModalOpen && this.renderModal()}
      </div>
    )
  }
}

export default DisclaimerLink
