import React from "react"
import PropTypes from "prop-types"
import MapContainer from "geo_me-react-components/lib/containers/map"
import connectToFlux from "geo_me-react-next/lib/connectToFlux"
import MapView from "./view"

const Map = (props) => (
  <MapContainer>{(viewProps) => <MapView {...viewProps} {...props} />}</MapContainer>
)

Map.propTypes = {
  mapFactory: PropTypes.shape({ createMap: PropTypes.func.isRequired }).isRequired,
}

const queries = {
  isShowingTraffic: "map.isShowingTraffic",
}

export default connectToFlux(queries, {})(Map)
