import { Command } from "geo_me-flux"

class ClearSelectedApron extends Command {
  execute() {
    this.dispatch({
      type: this.actionTypes.APRON_UNSELECTED
    })
  }
}

export default ClearSelectedApron
