import React from "react"
import PropTypes from "prop-types"
import LinkPrimary from "geo_me-shell-aero-components/lib/ui/linkPrimary"

const LoginLinkView = ({ isPrivate, large, light, translate }) => {
  if (isPrivate) return null
  return (
    <LinkPrimary href="https://www.markethub.shell.com" target="_top" large={large} light={light}>
      {translate("auth.button")}
    </LinkPrimary>
  )
}

LoginLinkView.propTypes = {
  isPrivate: PropTypes.bool.isRequired,
  large: PropTypes.bool,
  light: PropTypes.bool,
  translate: PropTypes.func.isRequired,
}

export default LoginLinkView
