import React from "react"
import ResourcesContainer from "geo_me-react-components/lib/containers/resources"
import connectToFlux from "geo_me-react-next/lib/connectToFlux"

const shouldRefresh = (props) => {
  return props.bounds != null
}

const useDrivingDistances = (props) => {
  return !!props.selectedPlace
}

const getReferencePoint = (props) => {
  return props.selectedPlace && props.selectedPlace.origin
}

const changeConstraints = (constraints, props) => {
  return {
    ...constraints,
    ...props.directionsOptions,
    origin: getReferencePoint(props),
    drivingDistances: useDrivingDistances(props),
    channel: props.channel,
  }
}

const changeMetadata = (metadata, props) => {
  return {
    ...metadata,
    referencePoint: getReferencePoint(props),
  }
}

const Resources = (props) => (
  <ResourcesContainer
    {...props}
    changeConstraints={(constraints) => changeConstraints(constraints, props)}
    changeMetadata={(metadata) => changeMetadata(metadata, props)}
    shouldRefresh={() => shouldRefresh(props)}
  />
)

const queries = {
  bounds: "map.geometry.bounds",
  withAll: "filters.constraints.withAll",
  withAny: "filters.constraints.withAny",
  radius: "resources.constraints.radius",
  selectedPlace: "search.selectedPlace",
  mapCenter: "map.geometry.offsetCenter",
  channel: "config.gApiSettings.channel",
  selectedLocation: "locations.selected",
}

export default connectToFlux(queries, {})(Resources)
