import React from "react"
import PropTypes from "prop-types"
import ButtonRounded from "geo_me-shell-aero-components/lib/ui/buttonRounded"
import ButtonSecondary from "geo_me-shell-aero-components/lib/ui/buttonSecondary"
import ButtonIcon from "geo_me-shell-aero-components/lib/ui/buttonIcon"
import DialogContainer from "geo_me-shell-aero-components/lib/ui/dialogContainer"
import Pane from "geo_me-shell-aero-components/lib/ui/pane"
import FixedFooter from "../../ui/fixedFooter"
import BackArrowIcon from "../../../assets/svg/backArrowIcon"
import FavouriteIcon from "../../../assets/svg/favouriteIcon"
import AuthStates from "../../../values/authStates"
import PricingComponent from "./pricing"

class InfoWindowView extends React.Component {
  static propTypes = {
    dialogsShouldBeDisabled: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    mobileMapVisibility: PropTypes.string.isRequired,
    onCloseAllUiSections: PropTypes.func.isRequired,
    onDismissal: PropTypes.func.isRequired,
    onSetMobileMapVisibility: PropTypes.func.isRequired,
    onReturnToApronList: PropTypes.func.isRequired,
    onBookARefuel: PropTypes.func.isRequired,
    onPhoneNumberLinkClick: PropTypes.func.isRequired,
    onEmailLinkClick: PropTypes.func.isRequired,
    onWebsiteLinkClick: PropTypes.func.isRequired,
    selectedLocation: PropTypes.object.isRequired,
    selectedApronId: PropTypes.number,
    isFavourite: PropTypes.bool.isRequired,
    authStatus: PropTypes.string.isRequired,
    onAddFavourite: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
  }

  CARD_TYPES = [
    "master_card",
    "visa_card",
    "savcarnet_card",
    "fuelfly_card",
    "sav_card",
    "others_card",
    "cashacceptance",
    "amex_card",
  ]
  LOYALTY_TYPES = ["aerous_card", "aerobrazil_card", "airmilescanada_card"]

  isPrivate() {
    return this.props.authStatus === AuthStates.PRIVATE
  }

  renderMapAndListToggle() {
    if (!this.props.isMobile) return null
    const buttonTranslationType =
      this.props.mobileMapVisibility === "hidden" ? "show_map" : "close_map"
    return (
      <FixedFooter filled>
        <div className="group-padding-x-very-small group-padding-y-very-small">
          {this.isPrivate() && <div className="u-mb0.5">{this.renderBookARefuelButton()}</div>}
          <ButtonRounded
            full
            onClick={this.props.onSetMobileMapVisibility}
            className="test-map-list-toggle"
          >
            {this.props.translate(`info_window.map_toggle.${buttonTranslationType}`)}
          </ButtonRounded>
        </div>
      </FixedFooter>
    )
  }

  renderTitle() {
    const { shipto_name, airport_name } = this.props.selectedLocation

    return (
      <div className="u-flex-row u-flex-center">
        {this.renderBackArrow()}
        {this.props.selectedApronId ? shipto_name : airport_name}
        {this.renderFavouriteButton()}
      </div>
    )
  }

  renderBackArrow() {
    if (this.props.selectedApronId) {
      return (
        <ButtonIcon
          className="info-window__back-button"
          onClick={this.props.onReturnToApronList}
          icon={BackArrowIcon}
        />
      )
    }
    return null
  }

  renderFavouriteButton() {
    if (this.isPrivate()) {
      return (
        <ButtonIcon
          className="info-window__favourite-button"
          onClick={this.props.onAddFavourite}
          icon={() => <FavouriteIcon filled={this.props.isFavourite} />}
        />
      )
    }
    return null
  }

  renderAddress() {
    const { city, country } = this.props.selectedLocation
    return (
      <div className="info-window__section info-window__section--address">
        <div>{city}</div>
        <div>{country.name}</div>
      </div>
    )
  }

  renderCodes() {
    const { iata_code, icao } = this.props.selectedLocation
    return (
      <div className="info-window__section">
        {!!iata_code && (
          <div>
            <strong>{this.props.translate("info_window.iata")}: </strong>
            {iata_code}
          </div>
        )}
        <div>
          <strong>{this.props.translate("info_window.icao")}: </strong>
          {icao}
        </div>
      </div>
    )
  }

  renderFuelingHours() {
    if (!this.props.selectedLocation.fuelservice) return null
    return (
      <div className="info-window__section">
        <strong>{this.props.translate(`info_window.fueling_hours_title`)}: </strong>
        {this.props.selectedLocation.fuelservice}
      </div>
    )
  }

  renderControlTowerHours() {
    if (!this.props.selectedLocation.control_tower_hours) return null
    return (
      <div className="info-window__section">
        <strong>{this.props.translate(`info_window.control_tower_hours_title`)}: </strong>
        {this.props.selectedLocation.control_tower_hours}
      </div>
    )
  }

  renderLoyaltyAndOther() {
    const acceptedLoyalty = this.LOYALTY_TYPES.filter(
      (loyaltyName) => this.props.selectedLocation[loyaltyName]
    )
    if (acceptedLoyalty.length == 0) return null

    return (
      <div className="info-window__section">
        <strong>{this.props.translate(`info_window.loyalty_title`)}:</strong>
        {acceptedLoyalty.map((loyaltyName) => (
          <div key={loyaltyName}>{this.props.translate(`loyalty.${loyaltyName}`)}</div>
        ))}
      </div>
    )
  }

  renderCardsAccepted() {
    const acceptedCards = this.CARD_TYPES.filter(
      (cardName) => this.props.selectedLocation[cardName]
    )
    if (acceptedCards.length == 0) return null
    return (
      <div className="info-window__section">
        <strong className="info-window__title">
          {this.props.translate(`info_window.cards_title`)}:{" "}
        </strong>
        {acceptedCards.map((cardName) => this.props.translate(`cards.${cardName}`)).join(", ")}
      </div>
    )
  }

  renderBookARefuelButton() {
    return (
      <ButtonSecondary
        onClick={this.props.onBookARefuel}
        className="info-window__book-refuel-button u-w100%"
      >
        {this.props.translate("info_window.book_a_refuel")}
      </ButtonSecondary>
    )
  }

  render() {
    if (!this.props.selectedLocation) return null
    return (
      <DialogContainer
        title={this.renderTitle()}
        onClose={this.props.onDismissal}
        onDisabledClick={this.props.onCloseAllUiSections}
        disabled={this.props.dialogsShouldBeDisabled}
        isLastPane={!this.isPrivate()}
      >
        <div className="info-window">
          {this.renderAddress()}
          {this.renderCodes()}
          <PricingComponent
            location={this.props.selectedLocation}
            locationId={this.props.selectedLocation.id}
            shipToNumber={this.props.selectedLocation.shipto_number}
            fuelTypes={this.props.selectedLocation.fuel_types}
            translate={this.props.translate}
          />
          {this.renderFuelingHours()}
          {this.renderControlTowerHours()}
          {this.renderLoyaltyAndOther()}
          {this.renderCardsAccepted()}
          {this.renderMapAndListToggle()}
        </div>
        {!this.props.isMobile && this.isPrivate() && (
          <FixedFooter>
            <Pane
              isLastPane
              disabled={this.props.dialogsShouldBeDisabled}
              onDisabledClick={this.props.onCloseAllUiSections}
            >
              <div className="group-padding-x-very-small group-padding-y-very-small">
                {this.renderBookARefuelButton()}
              </div>
            </Pane>
          </FixedFooter>
        )}
      </DialogContainer>
    )
  }
}

export default InfoWindowView
