import React from "react"
import FiltersPaneView from "./view"
import AuthStates from "../../values/authStates"
import connectToFlux from "geo_me-react-next/lib/connectToFlux"
import withI18n from "geo_me-react-next/lib/withI18n"
import FiltersContainer from "geo_me-react-components/lib/containers/filters"

class FiltersPane extends React.Component {
  componentWillUnmount() {
    if (this.props.isShowingFiltersSection) this.props.layoutToggleUiSection("filters")
  }

  handleToggleFiltersSection() {
    if (this.props.isShowingSettingsSection) {
      this.props.layoutToggleUiSection("settings")
    }
    this.props.layoutToggleUiSection("filters")
  }

  handleClearFilters() {
    this.props.filtersClear()
  }

  getFilterGroups() {
    const { filterGroups, authStatus } = this.props
    if (authStatus === AuthStates.PRIVATE)
      return filterGroups.filter(({ name }) => name === "fuels")
    return filterGroups
  }

  render() {
    return (
      <FiltersContainer>
        {(viewProps) => (
          <FiltersPaneView
            {...this.props}
            {...viewProps}
            onToggleFiltersSection={(...args) => this.handleToggleFiltersSection(...args)}
            onClearFilters={(...args) => this.handleClearFilters(...args)}
            filterGroups={this.getFilterGroups()}
          />
        )}
      </FiltersContainer>
    )
  }
}

const commands = { layoutToggleUiSection: "layout.toggleUiSection", filtersClear: "filters.clear" }
const queries = {
  filters: "filters.config",
  filterGroups: "filters.config.groups",
  isMobile: "environment.isMobile",
  authStatus: "environment.authStatus",
  isShowingFiltersSection: "layout.openedUiSections.filters",
  isShowingSettingsSection: "layout.openedUiSections.settings",
}

export default withI18n(connectToFlux(queries, commands)(FiltersPane))
