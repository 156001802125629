import React from "react"
import PropTypes from "prop-types"
import { FIXED_FOOTER } from "../../values/elementIds"

class AppLayoutView extends React.Component {
  static propTypes = {
    renderMap: PropTypes.func.isRequired,
    renderHeader: PropTypes.func.isRequired,
    renderContent: PropTypes.func.isRequired,
  }

  constructor() {
    super()
    this.resetScrollPosition = this.resetScrollPosition.bind(this)
  }

  resetScrollPosition() {
    if (this.bottomContent) this.bottomContent.scrollTop = 0
  }

  render() {
    const { mobileMapVisibility, renderMap, renderHeader, renderContent } = this.props
    const bottomContentClassName = [
      "app__bottom-content",
      mobileMapVisibility === "full" && "app__bottom-content--hidden",
      mobileMapVisibility === "partial" && "app__bottom-content--pushed-to-bottom",
    ]
      .filter(Boolean)
      .join(" ")

    return (
      <div className="app">
        <div className="app__map-container">{renderMap()}</div>
        <div className="app__top-content">{renderHeader()}</div>
        <div className={bottomContentClassName} ref={(node) => (this.bottomContent = node)}>
          {renderContent({ resetScrollPosition: this.resetScrollPosition })}
        </div>
        <div id={FIXED_FOOTER} className="app__fixed-content" />
      </div>
    )
  }
}

export default AppLayoutView
