import Store from "geo_me-flux/lib/store"
import RequestStates from "../values/requestStates"

class Pricing extends Store {
  static initClass() {
    this.addActions({
      FUEL_TYPE_SELECTED(action) {
        this.set("selectedFuelType", action.payload)
      },
      SEARCH_PLACE_SELECTED(action) {
        if (action.payload.type === "RESOURCE") {
          this.handleSelection(action.payload.meta.original)
        }
      },
      LOCATION_SELECTED(action) {
        this.handleSelection(action.payload)
      },
      APRON_SELECTED() {
        this.clearAll()
      },
      CONTRACTS_LOADED(action) {
        this.set("contractsState", RequestStates.LOADED)
        this.set("contracts", action.payload)
      },
      CONTRACTS_REQUESTED() {
        this.set("contractsState", RequestStates.REQUESTED)
      },
      CONTRACT_SELECTED(action) {
        this.set("selectedContract", action.payload)
      },
      PRICES_LOADED(action) {
        if (!action.error) {
          this.set("prices", action.payload)
          this.set("pricesState", RequestStates.LOADED)
        } else {
          this.set("pricesState", null)
        }
      },
      PRICES_REQUESTED() {
        this.set("pricesState", RequestStates.REQUESTED)
      },
    })
  }

  handleSelection(location) {
    if (this.get("pricesFor") !== location.id) this.clearAll()
    this.set("pricesFor", location.id)
  }
}
Pricing.initClass()

export default Pricing
