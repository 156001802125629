import Base from "geo_me-sdk/lib/app/store/resource/locations"

class Locations extends Base {
  static initClass() {
    this.addActions({
      APRON_SELECTED(action) {
        this.set("selectedApronId", action.payload)
      },
      APRON_UNSELECTED() {
        this.clear("selectedApronId")
      }
    })
  }

  setSelected(location) {
    super.setSelected(location)
    const selectedApronId = this.get("selectedApronId")
    if (selectedApronId && !this.isApronAtLocation(selectedApronId, location)) {
      this.clear("selectedApronId")
    }
  }

  isApronAtLocation(apronId, location) {
    return !!location.aprons.find(a => a.id == apronId)
  }

  clearSelected() {
    super.clearSelected()
    this.clear("selectedApronId")
  }
}

Locations.initClass()

export default Locations
