import { Command } from "geo_me-flux"

class LoadFavourites extends Command {
  constructor(options) {
    super(...arguments)
    this.httpClient = options.httpClient
  }

  execute() {
    this.dispatch({
      type: this.actionTypes.FAVOURITES_LOADING,
    })

    this.httpClient
      .get("/favourites")
      .then(({ responseJSON: favourites }) => {
        this.dispatch({
          type: this.actionTypes.FAVOURITES_LOADED,
          payload: favourites,
        })
      })
      .catch(({ error }) => {
        this.dispatch({
          type: this.actionTypes.FAVOURITES_LOADED,
          payload: error,
          error: true,
        })
      })
  }
}

export default LoadFavourites
