import React from "react"
import PropTypes from "prop-types"
import ChevronIcon from "../../../../assets/svg/chevronIcon"

class PriceResultView extends React.Component {
  static propTypes = {
    translate: PropTypes.func.isRequired,
    prices: PropTypes.shape({
      Pricing_Disclaimer: PropTypes.string.isRequired,
      PriceHeaders: PropTypes.arrayOf(
        PropTypes.shape({
          MainPriceEntry: PropTypes.object.isRequired,
        })
      ),
    }),
  }

  constructor(...opts) {
    super(...opts)
    this.state = { ...this.state, lineItemsShown: [] }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ lineItemsShown: [] })
  }

  renderLineItemsButton(index) {
    return (
      <button
        className={`clean-button u-flex-row info-window__pricing-items-toggle-button`}
        onClick={() => {
          const newState = this.state.lineItemsShown.map((v) => v)
          newState[index] = !newState[index]
          this.setState({ lineItemsShown: newState })
        }}
      >
        {this.props.translate("info_window.pricing.expand")}
        <ChevronIcon direction={this.state.lineItemsShown[index] ? "up" : "down"} />
      </button>
    )
  }

  renderLineItem(lineItem) {
    return (
      <div key={lineItem.PRODUCT} className="u-flex-row u-flex-justify u-mt1">
        <div>
          {lineItem.PRIDET}
          <div>{`${lineItem.PRICE} ${lineItem.CURR} / ${lineItem.PER} ${lineItem.UOM}`}</div>
        </div>
        <div>{lineItem.REMARKS && `(${lineItem.REMARKS})`}</div>
      </div>
    )
  }

  renderPriceHeader(header, index) {
    // NO more EHUB_MSG.
    const {
      CONTRACTID: contractId,
      PRICE: price,
      PRIDET: priceDetails,
      CURR: currency,
      PER: pricePer,
      UOM: unitOfMeasure,
    } = header.MainPriceEntry
    return (
      <div className="info-window__section info-window__price-result" key={index}>
        <div>
          <strong>{this.props.translate("info_window.pricing.contract_id")}: </strong>
          {contractId}
        </div>
        <div>
          <strong>{priceDetails}</strong>
        </div>
        <div>{`${price} ${currency} / ${pricePer} ${unitOfMeasure}`}</div>
        {this.renderLineItemsButton(index)}
        {this.state.lineItemsShown[index] && this.renderLineItem(header.MainPriceEntry)}
      </div>
    )
  }

  render() {
    if (!this.props.prices) return null
    const priceHeaders = this.props.prices.PriceHeaders
    return (
      <div className="u-mt1">
        {priceHeaders.map((header, index) => this.renderPriceHeader(header, index))}
      </div>
    )
  }
}

export default PriceResultView
