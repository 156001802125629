import { Command } from "geo_me-flux"

class DismissErrorMessageCommand extends Command {
  execute(params) {
    this.dispatch({
      type: this.actionTypes.ERROR_MESSAGE_DISMISSED
    })
  }
}

export default DismissErrorMessageCommand
