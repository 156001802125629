import React, { Component } from "react"
import PropTypes from "prop-types"
import SettingsPaneView from "./view"
import connectToFlux from "geo_me-react-next/lib/connectToFlux"
import withI18n from "geo_me-react-next/lib/withI18n"

class SettingsPane extends Component {
  static propTypes = {
    onDismiss: PropTypes.func.isRequired,
    i18NSelectDistanceUnit: PropTypes.func.isRequired,
    locationsFind: PropTypes.func.isRequired,
    locationsSelectApron: PropTypes.func.isRequired,
    isMobile: PropTypes.any,
    authStatus: PropTypes.any,
    isSettingsWindowActive: PropTypes.any,
    distanceUnit: PropTypes.any,
    favourites: PropTypes.any,
    favouritesStatus: PropTypes.any,
  }

  handleDistanceUnitSelect(unit) {
    this.props.i18NSelectDistanceUnit(unit)
  }

  handleFavouriteSelect(favourite) {
    this.props.locationsFind({
      resource: "locations",
      id: favourite.parent_id || favourite.location_id,
    })
    if (favourite.parent_id) {
      this.props.locationsUnselect()
      this.props.locationsSelectApron(favourite.location_id)
    }
    this.props.onDismiss()
  }

  handleDownloadCSVClicked() {
    window.location.pathname = "/api/v1/csv"
  }

  render() {
    return (
      <SettingsPaneView
        {...this.props}
        onDistanceUnitSelect={(...args) => this.handleDistanceUnitSelect(...args)}
        onFavouriteSelect={(...args) => this.handleFavouriteSelect(...args)}
        onDownloadCSVClicked={(...args) => this.handleDownloadCSVClicked(...args)}
      />
    )
  }
}

const commands = {
  i18NSelectDistanceUnit: "i18n.selectDistanceUnit",
  locationsFind: "locations.find",
  locationsSelectApron: "locations.selectApron",
  locationsUnselect: "locations.unselect",
}

const queries = {
  isMobile: "environment.isMobile",
  authStatus: "environment.authStatus",
  isSettingsWindowActive: "layout.openedUiSections.settings",
  distanceUnit: "i18n.intlData.formats.number.distance.unit",
  favourites: "favourites.favourites",
  favouritesStatus: "favourites.status",
}
export { SettingsPane as UnwrappedSettingsPane }
export default withI18n(connectToFlux(queries, commands)(SettingsPane))
