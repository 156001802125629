import IntlData from "./intlData"
import Filters from "./filters"
const deployEnv = process.env.DEPLOY_ENV || ""

export default {
  intlData: IntlData,
  filters: Filters,
  baseUrl: "/api/v2",
  gApiSettings: {
    key: process.env.GOOGLE_API_KEY,
  },
  resources: {
    locations: {
      enabled: true,
    },
  },
  map: {
    defaultTargetZoom: 13,
    minSearchLocations: 3,
    settings: {
      disableDefaultUI: true,
      zoom: 4,
      center: {
        lat: 52.23,
        lng: 18.01,
      },
    },
  },
}
