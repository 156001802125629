import React from "react"
import FiltersContainer from "geo_me-react-components/lib/containers/filters"
import FiltersView from "./view"

const Filters = () => (
  <FiltersContainer dontRenderEmptyFilters>
    {(viewProps) => <FiltersView {...viewProps} isMobile={false} alwaysShowSelectedFilters />}
  </FiltersContainer>
)

export default Filters
