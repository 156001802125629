import { Command } from "geo_me-flux"

class BookARefuel extends Command {
  constructor(opts) {
    super(opts)
    this.parentPage = opts.parentPage
  }

  execute(shipToNumber) {
    const uniqueSessionId = this.query("config.session_id")
    const materialGroup = this.query("pricing.selectedFuelType")

    if (this.parentPage.RedirectPage) {
      this.parentPage.RedirectPage(uniqueSessionId, shipToNumber, materialGroup)
    } else {
      console.error("'RedirectPage' missing from parent page")
    }

    this.dispatch({
      type: this.actionTypes.REFUEL_BOOKED,
      payload: shipToNumber
    })
  }
}

export default BookARefuel
