import React, { Fragment } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import PlaceIcon from "../../../assets/svg/placeIcon"
import AirportIcon from "../../../assets/svg/airportIcon"
import CompletionTypes from "geo_me-sdk/lib/app/value/completionType"

class CompletionItem extends React.Component {
  static propTypes = {
    item: PropTypes.shape({
      type: PropTypes.oneOf([CompletionTypes.GAPI, CompletionTypes.RESOURCE]).isRequired,
      meta: PropTypes.shape({
        original: PropTypes.shape({
          icao: PropTypes.string,
          iata_code: PropTypes.string,
        }),
      }),
    }),
  }

  renderCodes() {
    const { icao, iata_code: iataCode } = this.props.item.meta.original
    if (!icao || !iataCode) return null
    return (
      <div className="completions-item__codes">
        <div className="completions-item__codes-iata">
          <strong>IATA:</strong> {iataCode}
        </div>
        <div className="completions-item__codes-icao">
          <strong>ICAO:</strong> {icao}
        </div>
      </div>
    )
  }

  render() {
    const { className, onClick, onFocus, item, children } = this.props
    return (
      <div
        className={`${className} completions-item completions-item--${item.type}`}
        onClick={onClick}
        onMouseEnter={onFocus}
      >
        <div className="completions-item__icon">
          {item.type === CompletionTypes.GAPI ? <PlaceIcon /> : <AirportIcon />}
        </div>
        <div className="completions-item__info">
          <div className="completions-item__name">{children}</div>
          {item.type === CompletionTypes.RESOURCE && this.renderCodes()}
        </div>
      </div>
    )
  }
}

export default CompletionItem
