import React from "react"
import AuthStates from "../../values/authStates"
import connectToFlux from "geo_me-react-next/lib/connectToFlux"
import LoginLinkView from "./view"
import withI18n from "geo_me-react-next/lib/withI18n"

const LoginLink = ({ authStatus, translate, ...otherProps }) => (
  <LoginLinkView
    {...otherProps}
    authStatus={authStatus}
    translate={translate}
    isPrivate={authStatus === AuthStates.PRIVATE}
  />
)

const queries = {
  authStatus: "environment.authStatus",
}

export { LoginLink as UnwrappedLoginLink }
export default withI18n(connectToFlux(queries, {})(LoginLink))
