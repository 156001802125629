import Message from "./messages"
export default {
  locales: ["en_GB"],
  messages: Message,
  formats: {
    number: {
      distance: {
        unit: "miles"
      }
    }
  }
}
