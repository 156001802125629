import React, { PureComponent } from "react"
import CrossIcon from "geo_me-shell-aero-components/lib/assets/svg/crossIcon"
import PropTypes from "prop-types"

class DialogBox extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.any.isRequired,
  }

  render() {
    return (
      <div className="dialog-box">
        <div className="dialog-box__title-bar">
          <div className="dialog-box__title">{this.props.title}</div>
          <div className="dialog-box__close-button-container">
            <button className="dialog-box__close-button" onClick={this.props.onClose}>
              <CrossIcon className="dialog-box__close-icon" />
            </button>
          </div>
        </div>
        <div className="dialog-box__content">{this.props.children}</div>
      </div>
    )
  }
}

export { DialogBox as UnwrappedDialogBox }
export default DialogBox
