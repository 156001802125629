export default {
  localised: {
    en_GB: {
      fuels: {
        aerojet: "Aerojet",
        fuel_avgas: "AvGas",
        fuel_jet: "Jet",
      },
      cards: {
        sav_card: "Shell SAV Card",
        amex_card: "American Express",
        master_card: "Master Card",
        visa_card: "Visa Card",
        cashacceptance: "Cash",
        savcarnet_card: "Shell Carnet",
        fuelfly_card: "Fuel and Fly",
        others_card: "Others Card",
      },
      loyalty: {
        aerous_card: "AeroClass US",
        aerobrazil_card: "AeroClass Brazil",
        airmilescanada_card: "Air Miles Canada",
      },
      filters: {
        title: "Filters",
        clear: "Clear All",
        less: "Less",
        more: "More",
        fuels: {
          title: "Fuels",
        },
        cards: {
          title: "Cards Accepted",
        },
        loyalty: {
          title: "Loyalty Schemes",
        },
      },
      search: {
        placeholder: "Location, zip, or Airport Code",
        landing_page_label: "Find People, Places and Prices Globally",
      },
      distance: {
        value: "{distance, number, distance} {unit}",
        unit: {
          miles: "mi",
        },
      },
      locations_list: {
        title: "Refuel Nearby",
        distance: "{distance, number, distance}{unit} away",
        fueling_hours: "Fueling Hours: {hours}",
        control_tower_hours: "Control Tower Hours: {hours}",
        map_toggle: {
          list: "List",
          map: "Map",
        },
      },
      settings: {
        distance_units: {
          title: "Distance",
          miles: "Miles",
          km: "Kilometers",
        },
        favourites: {
          title: "Favourites",
          icao: "ICAO",
          empty_message: "You currently have no favourites saved",
        },
        download_csv: {
          title: "Download",
          cta: "Download all public data (CSV)",
        },
      },
      info_window: {
        map_toggle: {
          close_map: "Close Map",
          show_map: "See on Map",
        },
        iata: "IATA",
        icao: "ICAO",
        book_a_refuel: "Book a Refuel",
        disclaimer: "Disclaimer",
        disclaimer_text:
          "Shell makes reasonable efforts to provide timely information on this website, but cannot guarantee the accuracy of such information. As such, this information should not be relied upon when making a decision. Shell is not responsible for any action you may take as a result of relying on any information provided herein.",
        show_disclaimer: "Show Disclaimer",
        lubricants_title: "Lubricants Available",
        details_title: "Shell Details at Location",
        tel_label: "Tel",
        fax_label: "Fax",
        email_label: "Email",
        website_label: "Website",
        cards_title: "Cards Accepted",
        loyalty_title: "Loyalty Programs",
        fueling_hours_title: "Fueling Hours",
        control_tower_hours_title: "Control Tower Hours",
        favourites: {
          loader_title: "Fetching your favourites",
        },
        pricing: {
          title: "Prices",
          prices_button: "Show Prices",
          fuel_default: "Select a fuel",
          contract_default: "Select a contract",
          contract_loading: "Loading your contracts...",
          contract_none: "No contracts returned",
          button: "Show Prices",
          disclaimer: "See disclaimer",
          contract_id: "Contract ID",
          expand: "expand to see additional fees and surcharge",
          loader_title: "Fetching your prices",
          no_prices: "No Prices Available",
        },
      },
      lubricants: {
        aero_shell: "Aeroshell",
        aeroshell_turbine_engine_oil: "Turbine Engine Oil",
        aeroshell_piston_engine_oil: "Piston Engine Oil",
        aeroshell_grease: "Grease",
        aeroshell_fluid: "Fluids",
      },
      auth: {
        button: "Log in/Sign up",
        text: "Login to view your Shell contracted rates or sign up to become our customer",
      },
      errors: {
        pricing: {
          title: "Unable to find prices",
          message: "Sorry, something went wrong. Please try again later.",
        },
        contracts: {
          title: "Unable to find contracts",
          message: "Sorry, something went wrong. Please try again later.",
          select_text: "No contracts returned",
        },
        favourites: {
          load: {
            title: "Unable to find favourites",
            message: "Sorry, something went wrong. Please try again later.",
          },
          add: {
            title: "Favourite not added!",
            message: "Sorry, something went wrong. Please try again later.",
          },
        },
      },
    },
  },
}
