import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Locations from "./locations"
import Map from "geo_me-react-components/lib/components/map"
import { MAP_OPTIONS_DESKTOP, MAP_OPTIONS_MOBILE } from "./mapOptions"

class MapView extends React.Component {
  static propTypes = {
    locations: PropTypes.array,
    highlightedLocation: PropTypes.object,
    selectedLocation: PropTypes.object,
    directions: PropTypes.object,
    isShowingTraffic: PropTypes.bool,
    isMobile: PropTypes.bool,
    selectedPlace: PropTypes.object,
  }

  render() {
    const { isMobile } = this.props

    return (
      <Map {...this.props} mapOptions={isMobile ? MAP_OPTIONS_MOBILE : MAP_OPTIONS_DESKTOP}>
        {({ handleOverlayRequest, handleRendererRequest }) => (
          <Fragment>
            <Locations
              onOverlayRequest={handleOverlayRequest}
              locations={this.props.locations}
              highlighted={this.props.highlightedLocation}
              selected={this.props.selectedLocation}
              selectedPlace={this.props.selectedPlace}
            />
          </Fragment>
        )}
      </Map>
    )
  }
}

export default MapView
