import React from "react"
import SearchContainer from "geo_me-react-components/lib/containers/search"
import SearchView from "./view"
import connectToFlux from "geo_me-react-next/lib/connectToFlux"
import { withContexts } from "geo_me-react-next/lib/context/withContexts"
import { EnvironmentContext } from "geo_me-react-next/lib/context/environmentContext"
import withI18n from "geo_me-react-next/lib/withI18n"

const Search = ({
  layoutChangeAppPanelDimensions,
  isMobile,
  translate,
  isFiltersWindowActive,
  isSettingsWindowActive,
  layoutToggleUiSection,
  selectedPlace,
}) => (
  <SearchContainer>
    {(props) => (
      <SearchView
        {...props}
        isMobile={isMobile}
        translate={translate}
        isFiltersWindowActive={isFiltersWindowActive}
        isSettingsWindowActive={isSettingsWindowActive}
        onAppPanelDimensionsChange={(dimensions) =>
          window.requestAnimationFrame(() => layoutChangeAppPanelDimensions(dimensions))
        }
        onSettingsWindowToggle={() => {
          if (isFiltersWindowActive) layoutToggleUiSection("filters")
          layoutToggleUiSection("settings")
        }}
        selectedPlace={selectedPlace}
      />
    )}
  </SearchContainer>
)

const commands = {
  layoutChangeAppPanelDimensions: "layout.changeAppPanelDimensions",
  layoutToggleUiSection: "layout.toggleUiSection",
}

const queries = {
  isFiltersWindowActive: "layout.openedUiSections.filters",
  isSettingsWindowActive: "layout.openedUiSections.settings",
  selectedPlace: "search.selectedPlace",
}

export default withI18n(withContexts(EnvironmentContext)(connectToFlux(queries, commands)(Search)))
