import { Command } from "geo_me-flux"

class CloseAllUiSections extends Command {
  execute() {
    this.dispatch({
      type: this.actionTypes.LAYOUT_ALL_UI_SECTIONS_CLOSED
    })
  }
}

export default CloseAllUiSections
