import { Command } from "geo_me-flux"

class AddFavourite extends Command {
  constructor(options) {
    super(...arguments)
    this.httpClient = options.httpClient
    this.commandHandler = options.commandHandler
  }

  execute(params) {
    this.dispatch({
      type: this.actionTypes.FAVOURITE_ADDED,
      payload: {
        SHIPTO_NUMBER: params.shipToNumber,
        SHIPTO_NAME: params.shipToName,
        ICAO_CODE: params.icao,
        location_id: params.id,
        parent_id: params.parentId
      }
    })

    this.httpClient
      .post("/favourites", {
        id: params.id
      })
      .then(favourites => {
        this.dispatch({
          type: this.actionTypes.FAVOURITE_SAVED
        })
        this.commandHandler.run("favourites.load")
      })
      .catch(({ error }) => {
        this.dispatch({
          type: this.actionTypes.FAVOURITE_SAVED,
          payload: { location_id: params.id },
          error: true
        })
      })
  }
}

export default AddFavourite
