import React from "react"
import PropTypes from "prop-types"
import ButtonRounded from "geo_me-shell-aero-components/lib/ui/buttonRounded"
import FavouritesStatus from "../../../values/favouritesStatus"
import LoadingIndicator from "../../loadingIndicator"

class FavouritesView extends React.Component {
  static propTypes = {
    favourites: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
  }

  renderFavourite(favourite) {
    const { SHIPTO_NAME, SHIPTO_NUMBER, ICAO_CODE, location_id, parent_id } = favourite
    return (
      <ButtonRounded
        className="u-w100% settings__favourites-button"
        key={SHIPTO_NUMBER}
        onClick={() => this.props.onSelect(favourite)}
      >
        <h4 className="title-primary u-mb0">{favourite.SHIPTO_NAME}</h4>
        <div className="settings__favourites-button-info">
          <strong>{this.props.translate("settings.favourites.icao")}:</strong> {ICAO_CODE}
        </div>
      </ButtonRounded>
    )
  }

  renderEmptyView() {
    return (
      <div className="settings__favourites-empty">
        {this.props.translate("settings.favourites.empty_message")}
      </div>
    )
  }

  render() {
    if (this.props.favouritesStatus === FavouritesStatus.LOADING)
      return (
        <LoadingIndicator
          className="pricing"
          title={this.props.translate("info_window.favourites.loader_title")}
        />
      )
    if (
      this.props.favouritesStatus === FavouritesStatus.LOADED &&
      this.props.favourites.length === 0
    ) {
      return this.renderEmptyView()
    }
    return (
      <div className="settings__favourites">
        {this.props.favourites.map((f) => this.renderFavourite(f))}
      </div>
    )
  }
}

export default FavouritesView
