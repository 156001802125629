import { Command } from "geo_me-flux"

export default class extends Command {
  constructor(opts) {
    super(opts)
    this.httpClient = opts.httpClient
    this.params = opts.params
    this.endpoint = opts.endpoint
  }

  execute() {
    this.dispatch({ type: this.actionTypes.AUTHENTICATION_STARTED })
    return this.httpClient
      .post(this.endpoint, this.params)
      .then(() => {
        this.dispatch({ type: this.actionTypes.AUTHENTICATION_COMPLETED })
      })
      .catch((e) => {
        this.dispatch({
          type: this.actionTypes.AUTHENTICATION_COMPLETED,
          error: true,
          payload: e,
        })
      })
  }
}
