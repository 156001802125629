import React, { Component } from "react"
import connectToFlux from "geo_me-react-next/lib/connectToFlux"
import PropTypes from "prop-types"
import PricingView from "./view"
import AuthStates from "../../../../values/authStates"

class Pricing extends Component {
  static propTypes = {
    fuelTypes: PropTypes.array,
    shipToNumber: PropTypes.string,
    locationId: PropTypes.number,
    pricingRequestPublicPrices: PropTypes.func.isRequired,
    pricingSelectFuelType: PropTypes.func.isRequired,
    pricingRequestContracts: PropTypes.func.isRequired,
    pricingSelectContract: PropTypes.func.isRequired,
    pricingRequestPrivatePrices: PropTypes.func.isRequired,
    selectedFuelType: PropTypes.any,
    contracts: PropTypes.any,
    selectedContract: PropTypes.any,
    prices: PropTypes.any,
    pricesState: PropTypes.any,
    contractsState: PropTypes.any,
    authStatus: PropTypes.any,
  }

  componentDidMount() {
    this.requestPricesNextTickIfPublic()
  }

  componentDidUpdate(prevProps) {
    const { locationId } = this.props
    if (prevProps.locationId !== locationId) this.requestPricesNextTickIfPublic()
  }

  requestPricesNextTickIfPublic() {
    const { authStatus, locationId, pricingRequestPublicPrices } = this.props
    if (authStatus === AuthStates.PUBLIC) {
      window.setTimeout(() => pricingRequestPublicPrices(locationId), 0)
    }
  }

  handleSelectFuelType(fuelType) {
    this.props.pricingSelectFuelType(fuelType)
    this.props.pricingRequestContracts({ shipToNumber: this.props.shipToNumber, fuelType })
  }

  handleSelectContract(contract) {
    this.props.pricingSelectContract(contract)
  }

  handleRequestPrices() {
    this.props.pricingRequestPrivatePrices({ shipToNumber: this.props.shipToNumber })
  }

  render() {
    return (
      <PricingView
        {...this.props}
        onSelectFuelType={(...args) => this.handleSelectFuelType(...args)}
        onSelectContract={(...args) => this.handleSelectContract(...args)}
        onRequestPrices={(...args) => this.handleRequestPrices(...args)}
      />
    )
  }
}

const commands = {
  pricingRequestPublicPrices: "pricing.requestPublicPrices",
  pricingSelectFuelType: "pricing.selectFuelType",
  pricingRequestContracts: "pricing.requestContracts",
  pricingSelectContract: "pricing.selectContract",
  pricingRequestPrivatePrices: "pricing.requestPrivatePrices",
}

const queries = {
  selectedFuelType: "pricing.selectedFuelType",
  contracts: "pricing.contracts",
  selectedContract: "pricing.selectedContract",
  prices: "pricing.prices",
  pricesState: "pricing.pricesState",
  contractsState: "pricing.contractsState",
  authStatus: "environment.authStatus",
}
export { Pricing as UnwrappedPricing }
export default connectToFlux(queries, commands)(Pricing)
