import React from "react"
import PropTypes from "prop-types"
import { Router } from "react-router"
import RoutingContainer from "geo_me-react-components/lib/containers/routing"
import AppContainer from "geo_me-react-next/lib/appContainer"
import Resources from "./resources"
import AppLayout from "./appLayout"
import ErrorMessageDialog from "./errorMessageDialog"
import Map from "./map"
import Search from "./search"
import GlobalDialogGroup from "./globalDialogGroup"
import EnvironmentContextProvider from "geo_me-react-next/lib/context/environmentContextProvider"

class App extends React.Component {
  render() {
    return (
      <AppContainer {...this.props}>
        <EnvironmentContextProvider mobileMatchMedia="(max-width: 768px)">
          <RoutingContainer
            history={this.props.routingHistory}
            registry={this.props.routingRegistry}
          />
          <Router {...this.props.routerProps} />
          <Resources />
          <ErrorMessageDialog />
          <AppLayout
            renderMap={() => <Map mapFactory={this.props.mapFactory} />}
            renderHeader={() => <Search />}
            renderContent={({ resetScrollPosition }) => (
              <GlobalDialogGroup resetScrollPosition={resetScrollPosition} />
            )}
          />
        </EnvironmentContextProvider>
      </AppContainer>
    )
  }
}

export default App
