import { Command } from "geo_me-flux"

class SelectFuelType extends Command {
  execute(fuelType) {
    this.dispatch({
      type: this.actionTypes.FUEL_TYPE_SELECTED,
      payload: fuelType
    })
  }
}

export default SelectFuelType
