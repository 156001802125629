import React from "react"
import PropTypes from "prop-types"
import ChevronIcon from "../../../assets/svg/chevronIcon"

class FiltersTitleView extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
  }

  renderClearButton() {
    if (!this.props.isOpen) return null
    return (
      <button
        className={`clean-button filters-title__clear-button u-pos-a`}
        onClick={this.props.onClear}
      >
        {this.props.translate("filters.clear")}
      </button>
    )
  }

  renderTitleContent() {
    return (
      <div className="filters-title__content u-flex-row u-flex-justify-center u-flex-center">
        <button
          className={`clean-button u-flex-row filters-title__toggle-button`}
          onClick={this.props.onClick}
        >
          {this.props.translate("filters.title")}
          <ChevronIcon direction={this.props.isOpen ? "up" : "down"} />
        </button>
        {this.renderClearButton()}
      </div>
    )
  }

  render() {
    return this.renderTitleContent()
  }
}

export default FiltersTitleView
