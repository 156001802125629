import { Command } from "geo_me-flux"

class RequestContracts extends Command {
  constructor(options) {
    super(...arguments)
    this.httpClient = options.httpClient
  }

  execute(params) {
    const { shipToNumber, fuelType } = params
    this.dispatch({
      type: this.actionTypes.CONTRACTS_REQUESTED,
    })

    this.httpClient
      .get("/contracts", { shipto_nbr: shipToNumber, material_grp: fuelType })
      .then(({ responseJSON: contracts }) => {
        this.dispatch({
          type: this.actionTypes.CONTRACTS_LOADED,
          payload: contracts,
        })
      })
      .catch(({ error }) => {
        this.dispatch({
          type: this.actionTypes.CONTRACTS_LOADED,
          payload: error,
          error: true,
        })
      })
  }
}

export default RequestContracts
