import Defaults from "./locales/default"
import _ from "lodash"

var localised = {}

var context = require.context("./locales", true, /\.js$/)
context.keys().forEach(function(key) {
  localised[key.replace("./", "").replace(".js", "")] = context(key).default
})

export default _.assign({}, Defaults, { localised: localised })
