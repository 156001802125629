import { Command } from "geo_me-flux"
import areValidPrices from "./util/priceValidator"
class RequestPrivatePrices extends Command {
  constructor(options) {
    super(...arguments)
    this.httpClient = options.httpClient
  }

  execute(params) {
    const { shipToNumber } = params
    const fuelType = this.query("pricing.selectedFuelType")
    const contract = this.query("pricing.selectedContract")

    this.dispatch({
      type: this.actionTypes.PRICES_REQUESTED,
    })

    this.httpClient
      .get("/prices", {
        shipto_nbr: shipToNumber,
        material_grp: fuelType,
        gsap_contract_nbr: contract,
      })
      .then(({ responseJSON: prices }) => {
        const message = { type: this.actionTypes.PRICES_LOADED }
        if (areValidPrices(prices)) {
          message.payload = prices
        } else {
          message.error = true
        }
        this.dispatch(message)
      })
      .catch(({ error }) => {
        this.dispatch({
          type: this.actionTypes.PRICES_LOADED,
          payload: error,
          error: true,
        })
      })
  }
}

export default RequestPrivatePrices
