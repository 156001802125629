import React from "react"
import PropTypes from "prop-types"
import connectToFlux from "geo_me-react-next/lib/connectToFlux"
import AppLayoutView from "./view"

const AppLayout = (props) => <AppLayoutView {...props} />

const queries = { mobileMapVisibility: "layout.mobileMapVisibility" }
AppLayout.propTypes = {
  mobileMapVisibility: PropTypes.any,
}

export default connectToFlux(queries, {})(AppLayout)
