import { Command } from "geo_me-flux"

class SelectApron extends Command {
  execute(apronId) {
    this.dispatch({
      type: this.actionTypes.APRON_SELECTED,
      payload: apronId
    })
  }
}

export default SelectApron
