import React, { Component } from "react"
import ButtonRounded from "geo_me-shell-aero-components/lib/ui/buttonRounded"
import DialogContainer from "geo_me-shell-aero-components/lib/ui/dialogContainer"
import FixedFooter from "../../ui/fixedFooter"
import PropTypes from "prop-types"
import ChevronIcon from "../../../assets/svg/chevronIcon"

class ApronListView extends Component {
  static propTypes = {
    dialogsShouldBeDisabled: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    mobileMapVisibility: PropTypes.string.isRequired,
    onCloseAllUiSections: PropTypes.func.isRequired,
    onDismissal: PropTypes.func.isRequired,
    onSetMobileMapVisibility: PropTypes.func.isRequired,
    selectedLocation: PropTypes.object,
    onSelectApron: PropTypes.func.isRequired,
  }

  renderMapAndListToggle() {
    const { isMobile, mobileMapVisibility, onSetMobileMapVisibility, translate } = this.props
    if (!isMobile) return null
    const buttonTranslationType = mobileMapVisibility === "hidden" ? "show_map" : "close_map"
    return (
      <FixedFooter filled>
        <div className="group-padding-x-large group-padding-y">
          <ButtonRounded full onClick={onSetMobileMapVisibility} className="test-map-list-toggle">
            {translate(`info_window.map_toggle.${buttonTranslationType}`)}
          </ButtonRounded>
        </div>
      </FixedFooter>
    )
  }

  renderAprons() {
    return this.props.selectedLocation.aprons.map((apron) => {
      return (
        <button
          className="apron-list__item clean-button u-w100%"
          key={apron.id}
          onClick={() => this.props.onSelectApron(apron.id)}
        >
          {apron.shipto_name}
          <ChevronIcon direction="right" />
        </button>
      )
    })
  }

  render() {
    if (!this.props.selectedLocation) return null
    return (
      <DialogContainer
        title={this.props.selectedLocation.airport_name}
        onClose={this.props.onDismissal}
        onDisabledClick={this.props.onCloseAllUiSections}
        disabled={this.props.dialogsShouldBeDisabled}
        isLastPane={true}
      >
        <div className="apron-list">{this.renderAprons()}</div>
        {this.renderMapAndListToggle()}
      </DialogContainer>
    )
  }
}

export default ApronListView
