import React from "react"

const OptionsIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="5" height="17" viewBox="0 0 5 17" className={className}>
    <path
      fill="currentColor"
      d="M2,4 C3.1,4 4,3.1 4,2 C4,0.9 3.1,0 2,0 C0.9,0 0,0.9 0,2 C0,3.1 0.9,4 2,4 Z M2,6 C0.9,6 0,6.9 0,8 C0,9.1 0.9,10 2,10 C3.1,10 4,9.1 4,8 C4,6.9 3.1,6 2,6 Z M2,12 C0.9,12 0,12.9 0,14 C0,15.1 0.9,16 2,16 C3.1,16 4,15.1 4,14 C4,12.9 3.1,12 2,12 Z"
    />
  </svg>
)

export default OptionsIcon
