import React from "react"
import PropTypes from "prop-types"
import ButtonRaised from "geo_me-shell-aero-components/lib/ui/buttonRaised"
import PriceResult from "./priceResultView"
import AuthStates from "../../../../values/authStates"
import RequestStates from "../../../../values/requestStates"
import DisclaimerLink from "./disclaimerLink"
import LoadingIndicator from "../../../loadingIndicator"

class PricingView extends React.Component {
  static propTypes = {
    fuelTypes: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    selectedFuelType: PropTypes.string,
    contracts: PropTypes.array,
    selectedContract: PropTypes.string,
    prices: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    pricesState: PropTypes.string,
    contractsState: PropTypes.string,
    onLoginLinkClick: PropTypes.func.isRequired,
    onSelectFuelType: PropTypes.func.isRequired,
    onSelectContract: PropTypes.func.isRequired,
    onRequestPrices: PropTypes.func.isRequired,
  }

  static defaultProps = {
    selectedFuelType: "",
    selectedContract: "",
    contracts: [],
  }

  renderFuelTypeDropdown() {
    return (
      <select
        className="u-w100% info-window__pricing-fuel-select"
        onChange={(e) => this.props.onSelectFuelType(e.target.value)}
        value={this.props.selectedFuelType}
      >
        <option disabled value={""}>
          {this.props.translate("info_window.pricing.fuel_default")}
        </option>
        {this.props.fuelTypes &&
          this.props.fuelTypes.map((fuelType) => {
            return (
              <option value={fuelType.key} key={fuelType.key}>
                {fuelType.name}
              </option>
            )
          })}
      </select>
    )
  }

  contractSelectTitle() {
    const { contractsState, contracts, translate } = this.props
    if (contractsState === RequestStates.REQUESTED) {
      return translate("info_window.pricing.contract_loading")
    }
    if (contractsState === RequestStates.LOADED && contracts.length === 0) {
      return translate("info_window.pricing.contract_none")
    }
    return translate("info_window.pricing.contract_default")
  }

  renderContractDropdown() {
    const { contractsState, contracts, selectedContract, onSelectContract } = this.props
    const disabled = contractsState === RequestStates.LOADED && contracts.length === 0
    return (
      <select
        className={`u-w100% u-mt1 info-window__pricing-contract-select ${
          disabled ? "info-window__pricing-contract-select--disabled" : ""
        }`}
        onChange={(e) => onSelectContract(e.target.value)}
        value={selectedContract}
        disabled={disabled}
      >
        <option disabled={disabled} value={""}>
          {this.contractSelectTitle()}
        </option>
        {contracts.map((contract) => {
          return (
            <option value={contract.GSAP_CONTRACT_NBR} key={contract.GSAP_CONTRACT_NBR}>
              {contract.CONTRACT_DISPLAY}
            </option>
          )
        })}
      </select>
    )
  }

  renderPricesButton() {
    const { translate, onRequestPrices, selectedFuelType } = this.props
    return (
      <ButtonRaised
        className="info-window__prices-button"
        onClick={onRequestPrices}
        disabled={!selectedFuelType}
      >
        {translate("info_window.pricing.prices_button")}
      </ButtonRaised>
    )
  }

  renderPublicPrices() {
    if (!this.props.prices) return null
    return this.props.prices.map((p) => (
      <div className="u-mt1 info-window__pricing-public-price" key={p.id}>
        <strong>{p.fuel_name}: </strong>
        {`${p.price} ${p.currency} /${p.unit_multiple} ${p.unit}`}
      </div>
    ))
  }

  renderForPublic() {
    return (
      <div className="info-window__section">
        {Boolean(this.props.prices?.length) && (
          <h3 className="info-window__title">
            {this.props.translate("info_window.pricing.title")}:
          </h3>
        )}
        {this.props.pricesState === RequestStates.REQUESTED && (
          <LoadingIndicator
            className="pricing"
            title={this.props.translate("info_window.pricing.loader_title")}
          />
        )}
        {this.props.prices && this.renderPublicPrices()}
        <div className="info-window__pricing-actions">
          {this.renderDisclaimerLink(this.props.translate("info_window.disclaimer_text"))}
        </div>
        <div className="info-window__sign-up-section">
          <div className="info-window__sign-up-text u-mb1">{this.props.translate("auth.text")}</div>
          <a href="https://www.markethub.shell.com" target="_top">
            <ButtonRaised
              className="info-window__sign-up-button test-info-window-login-button"
              filled={true}
              onClick={this.props.onLoginLinkClick}
            >
              {this.props.translate("auth.button")}
            </ButtonRaised>
          </a>
        </div>
      </div>
    )
  }

  renderDisclaimerLink(disclaimerText) {
    if (!this.props.prices) return null
    return (
      <DisclaimerLink className="info-window__disclaimer-link" translate={this.props.translate}>
        {disclaimerText || this.props.prices.Pricing_Disclaimer}
      </DisclaimerLink>
    )
  }

  renderForPrivate() {
    const { fuelTypes, pricesState, prices, translate } = this.props
    if (!fuelTypes) return null
    return (
      <div className="info-window__section">
        <h3 className="info-window__title">{translate("info_window.pricing.title")}:</h3>
        {this.renderFuelTypeDropdown()}
        {this.renderContractDropdown()}
        <div className="info-window__pricing-actions">
          {this.renderPricesButton()}
          {this.renderDisclaimerLink()}
        </div>
        {pricesState === RequestStates.REQUESTED && (
          <LoadingIndicator
            className="pricing"
            title={translate("info_window.pricing.loader_title")}
          />
        )}
        {prices && <PriceResult prices={prices} translate={translate} />}
        {!prices && pricesState === RequestStates.LOADED && (
          <div className="info-window__no-prices">{translate("info_window.pricing.no_prices")}</div>
        )}
      </div>
    )
  }

  render() {
    if (this.props.authStatus === AuthStates.PUBLIC) {
      return this.renderForPublic()
    } else {
      return this.renderForPrivate()
    }
  }
}

export default PricingView
