import React, { Fragment } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import Filters from "../../filters"
import Pane from "geo_me-shell-aero-components/lib/ui/pane"
import FiltersTitle from "./title"

class FiltersPaneView extends React.Component {
  static propTypes = {
    isShowingFiltersSection: PropTypes.bool,
    onToggleFiltersSection: PropTypes.func.isRequired,
    onClearFilters: PropTypes.func.isRequired,
    filterGroups: PropTypes.object.isRequired,
    authStatus: PropTypes.string.isRequired,
  }

  static defaultProps = {
    isShowingFiltersSection: false,
  }

  handleClose() {
    this.props.onToggleFiltersSection()
  }

  renderFiltersButton() {
    return (
      <div className="group-padding-y-tiny">
        <FiltersTitle
          onClick={(...args) => this.handleClose(...args)}
          onClear={this.props.onClearFilters}
          isOpen={this.props.isShowingFiltersSection}
          className="test-open-filters-button"
          translate={this.props.translate}
        />
      </div>
    )
  }

  renderFiltersPane() {
    if (!this.props.isShowingFiltersSection) return null
    return (
      <Fragment>
        <Pane className="test-filters-pane" dark>
          {this.renderFiltersButton()}
        </Pane>
        <Pane className="u-z-above-level-1 u-w100% u-pos-a" dark isLastPane>
          <Filters />
        </Pane>
      </Fragment>
    )
  }

  render() {
    if (this.props.isShowingFiltersSection) return this.renderFiltersPane()
    return (
      <Pane dark isLastPane>
        {this.renderFiltersButton()}
      </Pane>
    )
  }
}

export default FiltersPaneView
